import { windowExists, globalExists } from './helpers.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
// Tested with nuxt 2.15 / vue 2.7
function nuxtWebDetect() {
  return windowExists() && (
  // @ts-ignore
  window['__NUXT__'] !== undefined || window['$nuxt'] !== undefined);
}
function nuxtSSRDetect() {
  // @ts-ignore
  return globalExists() && typeof global['__NUXT_PATHS__'] !== 'undefined';
}
export { nuxtSSRDetect, nuxtWebDetect };
