import {
  Component,
  EventEmitter,
  Input,
  model,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  InvoiceDtoWithValidity,
  MappedBillingDocument,
} from '../../../../accounts/types';
import {
  additionalIdentifierOptions,
  cityListsByCountry,
  countriesList,
  countryCode,
} from '../../../../accounts/constants';
import { RegexEnum } from '../../../model/enums/RegexPattern';
import { AccountService } from '../../../../accounts/account.service';
import { ReSubmitToZatcaInput } from '../../../types';

@Component({
  selector: 'rwa-billing-info-dialog',
  templateUrl: './billing-info-dialog.component.html',
  styleUrl: './billing-info-dialog.component.scss',
})
export class BillingInfoDialogComponent implements OnInit {
  readonly translationKey = 'createInvoice.';

  selectedIdentifier = null;

  showCustomCityNameField = false;

  // @Input() billingInfoDialogInput: MappedBillingDocument | PartialDocument;

  @Input() billingInfoDialogInput: ReSubmitToZatcaInput;

  invoiceData = model.required<InvoiceDtoWithValidity>();

  showModal: null | string = null;

  filteredCities = [];

  selectedCountry = { name: 'المملكة العربية السعودية' };

  selectedCity = { name: '' };

  cities = cityListsByCountry['المملكة العربية السعودية'];

  countries = countriesList;

  customerForm: UntypedFormGroup;

  additionalIdentifiers = additionalIdentifierOptions;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() closeDialog = new EventEmitter<any>();

  constructor(
    private fb: UntypedFormBuilder,
    public accountService: AccountService,
  ) {
    this.customerForm = this.fb.group({
      vatNumber: [
        '',
        [
          Validators.maxLength(15),
          Validators.required,
          Validators.pattern(RegexEnum.numberOnly),
        ],
      ],
      nonTaxableEntity: [false],
      country: ['', [Validators.required]],
      city: ['', [Validators.required, this.cityValidator.bind(this)]],
      customCityName: [''],
      districtName: ['', [Validators.required, Validators.maxLength(149)]],
      streetName: ['', [Validators.required, Validators.maxLength(139)]],
      postalCode: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5),
          Validators.pattern(RegexEnum.numberOnly),
        ],
      ],
      companyName: ['', [Validators.required, Validators.maxLength(149)]],
      additionalIdentifier: ['', Validators.required],
      additionalIdentifierNumber: [
        '',
        [
          Validators.required,
          Validators.maxLength(15),
          Validators.pattern(RegexEnum.numberOnly),
        ],
      ],
      buildingNumber: ['', Validators.maxLength(10)],
      additionalNumber: ['', Validators.maxLength(10)],
    });
  }

  ngOnInit(): void {
    this.onFormValueChanges();
    this.selectedCountry = { name: 'المملكة العربية السعودية' };
    this.cities = cityListsByCountry[this.selectedCountry.name];
    if (this.billingInfoDialogInput.wholeInvoice) {
      this.invoiceData.set({
        isValid: this.isFormValid(),
        vatNumber:
          this.invoiceData().vatNumber ||
          (this.billingInfoDialogInput.document as MappedBillingDocument)
            .vatNumber,
        companyName:
          this.invoiceData().companyName ||
          (this.billingInfoDialogInput.document as MappedBillingDocument)
            .billingAddress.company,
        additionalIdentifier:
          this.invoiceData().additionalIdentifier ||
          (this.billingInfoDialogInput.document as MappedBillingDocument)
            .additionalIdType,
        additionalIdentifierNumber:
          this.invoiceData().additionalIdentifierNumber ||
          (this.billingInfoDialogInput.document as MappedBillingDocument)
            .additionalIdValue,
        city:
          this.invoiceData().city ||
          (this.billingInfoDialogInput.document as MappedBillingDocument)
            .billingAddress.city,
        country:
          this.invoiceData().country ||
          (this.billingInfoDialogInput.document as MappedBillingDocument)
            .billingAddress.country,
        streetName:
          this.invoiceData().streetName ||
          (
            this.billingInfoDialogInput.document as MappedBillingDocument
          ).billingAddress.line1?.split(', ')[0],
        postalCode:
          this.invoiceData().postalCode ||
          (this.billingInfoDialogInput.document as MappedBillingDocument)
            .billingAddress.zip,
        districtName:
          this.invoiceData().districtName ||
          (this.billingInfoDialogInput.document as MappedBillingDocument)
            .billingAddress.line2,
        additionalNumber:
          this.invoiceData().additionalNumber ||
          (
            this.billingInfoDialogInput.document as MappedBillingDocument
          ).billingAddress.line1?.split(', ')[1] ||
          '',
        buildingNumber:
          this.invoiceData().buildingNumber ||
          (this.billingInfoDialogInput.document as MappedBillingDocument)
            .billingAddress.line3 ||
          '',
      });
    }
    console.log(this.invoiceData());

    this.fillDetailsAndDisableFieldsInForm(this.invoiceData());

    this.customerForm
      .get('additionalIdentifier')
      ?.valueChanges.subscribe((value) => {
        this.handleAdditionalIdentifierChange(value);
      });

    this.customerForm.get('city').valueChanges.subscribe((value) => {
      this.handleCityChange(value);
    });
  }

  onFormValueChanges(): void {
    this.customerForm.valueChanges.subscribe(() => {
      this.invoiceData.set({
        isValid: this.isFormValid(),
        vatNumber: this.vatNumber?.value,
        companyName: this.companyName?.value,
        additionalIdentifier: this.additionalIdentifier?.value,
        additionalIdentifierNumber: this.additionalIdentifierNumber?.value,
        country: this.selectedCountry.name,
        countryCode: countryCode[this.customerForm.get('country')?.value.name],
        city:
          this.customerForm.get('customCityName')?.value ||
          this.city?.value?.name,
        districtName: this.districtName?.value ?? '',
        streetName: this.streetName?.value,
        postalCode: this.postalCode?.value,
        additionalNumber: this.additionalNumber?.value,
        buildingNumber: this.buildingNumber?.value,
      });
    });
  }

  get vatNumber(): AbstractControl | null {
    return this.customerForm.get('vatNumber');
  }

  get nonTaxableEntity(): AbstractControl | null {
    return this.customerForm.get('nonTaxableEntity');
  }

  get city(): AbstractControl | null {
    return this.customerForm.get('city');
  }

  get customCityName(): AbstractControl | null {
    return this.customerForm.get('customCityName');
  }

  get country(): AbstractControl | null {
    return this.customerForm.get('country');
  }

  get districtName(): AbstractControl | null {
    return this.customerForm.get('districtName');
  }

  get streetName(): AbstractControl | null {
    return this.customerForm.get('streetName');
  }

  get postalCode(): AbstractControl | null {
    return this.customerForm.get('postalCode');
  }

  get companyName(): AbstractControl | null {
    return this.customerForm.get('companyName');
  }

  get additionalIdentifier(): AbstractControl | null {
    return this.customerForm.get('additionalIdentifier');
  }

  get additionalIdentifierNumber(): AbstractControl | null {
    return this.customerForm.get('additionalIdentifierNumber');
  }

  get buildingNumber(): AbstractControl | null {
    return this.customerForm.get('buildingNumber');
  }

  get additionalNumber(): AbstractControl | null {
    return this.customerForm.get('additionalNumber');
  }

  filterCities(event: { query: string }): void {
    const filtered: { name: string }[] = [];
    const { query } = event;
    for (let i = 0; i < this.cities.length; i++) {
      const city = this.cities[i];
      if (city.name.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
        filtered.push(city);
      }
    }
    this.filteredCities = filtered;
  }

  handleAdditionalIdentifierChange(value: string): void {
    const additionalIdentifierNumber = this.customerForm.get(
      'additionalIdentifierNumber',
    );

    if (value === "Client doesn't have a legal entity") {
      additionalIdentifierNumber?.disable();
      additionalIdentifierNumber?.setValue('');
    } else {
      additionalIdentifierNumber?.enable();

      if (value === 'Freelance License') {
        additionalIdentifierNumber?.setValidators([
          Validators.required,
          Validators.maxLength(10),
          Validators.pattern(RegexEnum.numberOnly),
        ]);
      } else {
        additionalIdentifierNumber?.setValidators([
          Validators.required,
          Validators.maxLength(15),
          Validators.pattern(RegexEnum.numberOnly),
        ]);
      }

      additionalIdentifierNumber?.updateValueAndValidity();
    }
  }

  handleCityChange(value: { name: string } | string): void {
    if (typeof value === 'object' && value?.name === 'أخرى') {
      this.showCustomCityNameField = true;

      if (!this.customerForm.get('customCityName')) {
        this.customerForm.addControl(
          'customCityName',
          new FormControl('', [Validators.required, Validators.maxLength(49)]),
        );
      }
    } else {
      this.showCustomCityNameField = false;

      if (this.customerForm.get('customCityName')) {
        this.customerForm.removeControl('customCityName');
      }
    }
  }

  updateAvailableCities(): void {
    this.cities = cityListsByCountry[this.selectedCountry.name];
    if (this.cityValidator(this.city))
      this.customerForm.get('city')?.patchValue('');
  }

  cityValidator(control: AbstractControl): { [key: string]: unknown } | null {
    const validCity = this.cities.find(
      (includedCity) => includedCity.name === control.value?.name,
    );
    return validCity ? null : { invalidCity: { value: control.value } };
  }

  checkIfCityIsValid(cityName: string): boolean {
    const validCity = this.cities.find(
      (includedCity) => includedCity.name === cityName,
    );
    return !!validCity;
  }

  fillDetailsAndDisableFieldsInForm(info: InvoiceDtoWithValidity): void {
    if (info.country) {
      this.selectedCountry = { name: info.country };
      this.updateAvailableCities();
    }

    if (info.city) {
      if (this.checkIfCityIsValid(info.city)) {
        this.showCustomCityNameField = false;

        if (this.customerForm.get('customCityName')) {
          this.customerForm.removeControl('customCityName');
        }

        this.customerForm.patchValue({
          city: { name: info.city },
        });
      } else {
        this.showCustomCityNameField = true;
        this.customerForm.patchValue({
          city: { name: 'أخرى' },
          customCityName: info.city,
        });
      }
      // this.selectedCity = { name: info.city };
    }

    if (info.additionalIdentifierNumber) {
      this.customerForm.patchValue({
        additionalIdentifierNumber: info.additionalIdentifierNumber.toString(),
      });
    }
    this.customerForm.patchValue({
      vatNumber: info.vatNumber,
      country: this.selectedCountry,
      districtName: info.districtName,
      streetName: info.streetName,
      postalCode: info.postalCode,
      buildingNumber: info.buildingNumber,
      additionalNumber: info.additionalNumber,
      companyName: info.companyName,
    });

    if (info.additionalIdentifier) {
      if (info.additionalIdentifier === `Client doesn't have a legal entity`) {
        this.customerForm.get('vatNumber')?.disable();
        this.customerForm.get('nonTaxableEntity')?.setValue(true);
        this.customerForm.patchValue({ additionalIdentifierNumber: '' });
        this.customerForm.get('additionalIdentifierNumber')?.disable();
      }
      this.customerForm.patchValue({
        additionalIdentifier: info.additionalIdentifier,
      });
    }

    this.invoiceData.set({
      ...info,
      isValid: this.isFormValid(),
    });
  }

  close(): void {
    // this.formGroup.reset();
    // this.formGroup.controls.refundValue.setValue('full');
    this.closeDialog.emit();
  }

  isFormValid(): boolean {
    for (const controlName in this.customerForm.controls) {
      const control = this.customerForm.get(controlName);
      if (control.enabled && control.invalid) {
        return false;
      }
    }
    return true;
  }
}
