import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import JSBarcode from 'jsbarcode';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import Qrious from 'qrious';
import { additionalIdentifiersInArabic } from '../../../accounts/constants';
import {
  MappedBillingDocument,
  BillingAddress,
  BillingDocumentLineItem,
} from '../../../accounts/types';

@Component({
  selector: 'rwa-print-invoice',
  templateUrl: './print-invoice.component.html',
  styleUrls: ['./print-invoice.component.scss'],
})
export class PrintInvoiceComponent {
  qrCode: string | undefined;

  barCode: string | undefined;

  additionalIdentifiersTranslation = additionalIdentifiersInArabic;

  readonly translationPrefix = 'billingHistory';

  @Input() invoice!: MappedBillingDocument;

  @Output() dataLoaded = new EventEmitter();

  printInvoice(): void {
    if (this.invoice) {
      this.barCode = this.generateBarcode(this.invoice.id);
      this.qrCode = this.getQRCodeDetails(
        this.invoice.date,
        this.invoice.total,
        this.invoice.tax || 0,
        this.invoice.isCreditNote,
      );
      this.dataLoaded.emit();
    }
  }

  getAddress(address: BillingAddress | undefined): string {
    if (!address) return '';
    const addressLine1 = address.line1 ?? '';
    const addressLine2 = address.line2 ?? '';
    const addressLine3 = address.line3 ?? '';
    const city = address.city ?? '';
    const country = address.country ?? '';
    const zip = address.zip ?? '';
    return `${addressLine1} ${addressLine2} ${addressLine3} ${city} ${country} ${zip}`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.invoice) {
      this.printInvoice();
    }
  }

  generateBarcode = (id: string): string => {
    const node = document.createElement('img');
    JSBarcode(node, id, {
      format: 'CODE128',
      width: 1.4,
      height: 32,
    });
    return (node as HTMLImageElement).src;
  };

  getTagLengthVal = (tag: number, valueStr: string): number[] => {
    const utf8Value = decodeURIComponent(encodeURIComponent(`${valueStr}`));
    const v = new TextEncoder().encode(utf8Value);
    const tl = Uint8Array.of(tag, v.byteLength);
    return [...tl, ...v];
  };

  getQRCodeDetails = (
    date: number,
    total: number,
    tax: number,
    isCreditNote: boolean,
  ): string => {
    const totalParsed = total / 100;
    const parsedDate = `${new Date(date * 1000).toISOString().split('.')[0]}Z`;

    const details = [
      ...this.getTagLengthVal(1, 'شركة رواء التقنية لتقنية المعلومات'),
      ...this.getTagLengthVal(2, '310188259700003'),
      ...this.getTagLengthVal(3, `${parsedDate}`),
      ...this.getTagLengthVal(4, `${Number(totalParsed).toFixed(2)}`),
    ];
    if (!isCreditNote) {
      details.push(
        ...this.getTagLengthVal(5, `${(Number(tax) / 100).toFixed(2)}`),
      );
    }
    const qr = new Qrious({
      size: 192,
      value: btoa(String.fromCharCode(...new Uint8Array(details))),
    });

    return qr.toDataURL('image/jpeg');
  };

  calculateDiscountPercentage(item: BillingDocumentLineItem): string {
    const { amount, discountAmount } = item;
    if (amount === 0) return '0.0';
    return ((discountAmount / amount) * 100).toFixed(1);
  }
}
