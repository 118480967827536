import { Injectable } from '@angular/core';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { FetchUserAttributesOutput } from 'aws-amplify/auth';
import { environment } from '../../environments/environment';
import { FeatureFlagService } from './types/feature-flag.service.interface';
import { FeatureFlags } from './enums/feature-flag.enum';
import { distinctUntilChangedV2 } from './distinct-until-changed-v2';
import { UserService } from './user.service';

interface LaunchDarklyUserContext extends LDClient.LDSingleKindContext {
  kind: string;
  key: string;
  email: string;
}

const anonymousUserKey = 'anon-user-key';
const anonymousUserObj: LDClient.LDContext = {
  kind: 'user',
  key: anonymousUserKey,
  anonymous: true,
};

@Injectable()
export class FeatureFlagServiceLD implements FeatureFlagService {
  private clientSideId: string;

  private client: LDClient.LDClient;

  private flagSubjects = new Map<string, ReplaySubject<any>>();

  private currentUser: LaunchDarklyUserContext | undefined;

  constructor(private userService: UserService) {
    this.clientSideId = environment.launchDarklyClientSideId;
    this.userService
      .getUser()
      .pipe(distinctUntilChangedV2((x, y) => x?.email === y?.email))
      .subscribe(
        async (user: FetchUserAttributesOutput | undefined): Promise<void> => {
          if (user && user.email) {
            const { email } = user;
            this.currentUser = {
              kind: 'user',
              email,
              key: email,
              anonymous: false,
            };
          } else {
            this.currentUser = undefined;
          }
          if (!this.client) {
            await this.initLaunchDarklyClient();
          }
          await this.setLaunchDarklyNewUser();
        },
      );
  }

  private async initLaunchDarklyClient() {
    this.client = LDClient.initialize(
      this.clientSideId,
      this.currentUser ?? anonymousUserObj,
      {
        bootstrap: 'localStorage',
        streaming: false,
      },
    );
    await this.client.waitForInitialization();
    this.client.setStreaming(true);
  }

  private async setLaunchDarklyNewUser() {
    this.client.setStreaming(false);
    if (this.currentUser) {
      await this.client.identify({
        ...this.currentUser,
        anonymous: false,
      });
    } else {
      await this.client.identify(anonymousUserObj);
    }
    await this.client.waitForInitialization();
    Object.entries(this.client.allFlags()).forEach(([key, value]) => {
      this.flagSubjects.get(key)?.next(value);
    });
    this.client.setStreaming(true);
  }

  private createFlagSubject<T extends keyof FeatureFlags>(
    featureFlagName: T,
    defaultValue: unknown,
  ) {
    const replaySubject = new ReplaySubject<FeatureFlags[T]>(1);
    const initialValue = this.client.variation(featureFlagName, defaultValue);
    replaySubject.next(initialValue);
    this.client.on(`change:${featureFlagName}`, (newValue) => {
      replaySubject.next(newValue);
    });
    this.flagSubjects.set(featureFlagName, replaySubject);
    return replaySubject;
  }

  isEnabled<T extends keyof FeatureFlags>(
    featureFlagName: T,
    defaultValue?: unknown,
  ): Observable<FeatureFlags[T]> {
    let featureFlagSubject: ReplaySubject<FeatureFlags[T]>;
    if (this.flagSubjects.has(featureFlagName)) {
      featureFlagSubject = this.flagSubjects.get(featureFlagName)!;
    }
    featureFlagSubject = this.createFlagSubject(featureFlagName, defaultValue);
    return featureFlagSubject.asObservable().pipe(
      filter((val) => val !== undefined),
      distinctUntilChanged(),
    );
  }

  variation<T extends keyof FeatureFlags>(
    featureFlagName: T,
    defaultValue?: unknown,
  ): Observable<FeatureFlags[T]> {
    let featureFlagSubject: ReplaySubject<FeatureFlags[T]>;
    if (this.flagSubjects.has(featureFlagName)) {
      featureFlagSubject = this.flagSubjects.get(featureFlagName)!;
    } else {
      featureFlagSubject = this.createFlagSubject(
        featureFlagName,
        defaultValue,
      );
    }
    return featureFlagSubject.asObservable().pipe(
      filter((val) => val !== undefined),
      distinctUntilChanged(),
    );
  }
}
