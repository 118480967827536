import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rwa-issue-credit-note-success',
  templateUrl: './issue-credit-note-success.component.html',
  styleUrl: './issue-credit-note-success.component.scss',
})
export class IssueCreditNoteSuccessComponent {
  @Input() dialogVisible: boolean;

  @Input({ required: true }) headingMsg: string;

  @Input({ required: true }) msg: string;

  @Input({ required: true }) planWarning: boolean;

  @Output() closeDialog = new EventEmitter<any>();

  close(action: any): void {
    this.closeDialog.emit(action);
  }
}
