<p-dialog [(visible)]="dialogVisible" [modal]="true" [draggable]="false" [resizable]="false" [style]="{width: '100%', maxWidth: '835px'}">
    <ng-template pTemplate="header">
        <div class="flex flex-column">
            <label class="text-lg font-medium">{{ 'Add Additonal Payment' | translate }}</label>
            <span class="text-base mt-2">{{ 'Please enter the customer’s payment details.' | translate }}</span>
        </div>
    </ng-template>

    <!-- <rwa-payment-screen
        [(paymentInfo)]="paymentInfo"
        [email]="email"
        >
    </rwa-payment-screen> -->

    <ng-template pTemplate="footer">
        <div class="flex align-items-center justify-content-end gap-3">
            <p-button label="{{ 'Cancel' | translate }}" [text]="true" (click)="close()"></p-button>
            <p-button label="{{ 'Export All' | translate }}" (click)="close()"></p-button>
        </div>
    </ng-template>
</p-dialog>