import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PaginatorState } from 'primeng/paginator';
import { takeWhile, tap } from 'rxjs/operators';

export interface TableAttributes {
  first: number;
  rows: number;
  recordCounts: number[];
  total: number;
  pageLinkSize: number;
  showFirstLastIcon?: boolean;
}

export interface OnPageChangePayload {
  offset: number;
  limit: number;
}

@Component({
  selector: 'rwa-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnDestroy {
  @Output() pageChange: EventEmitter<OnPageChangePayload> = new EventEmitter();

  defaultTableAttributes: TableAttributes = {
    first: 0,
    rows: 25,
    total: 0,
    recordCounts: [10, 25, 50, 100],
    pageLinkSize: 5,
    showFirstLastIcon: true,
  };

  @Input() tableAttributes: TableAttributes = this.defaultTableAttributes;

  rowsFormControl: UntypedFormControl = new UntypedFormControl(
    this.tableAttributes.rows,
  );

  destroyed = false;

  lng: 'en' | 'ar' = 'en';

  langCss: 'ltr' | 'rtl' = 'ltr';

  constructor() {
    this.rowsFormControl.valueChanges
      .pipe(
        takeWhile(() => !this.destroyed),
        tap((value) => {
          this.tableAttributes.rows = value;
          this.pageChange.emit({
            offset: +this.tableAttributes.first,
            limit: +this.tableAttributes.rows,
          });
        }),
      )
      .subscribe();
  }

  ngOnInit(): void {
    // TODO: add rtl support;
    this.langCss = this.lng === 'en' ? 'ltr' : 'rtl';
    // Assigning Default Values
    this.tableAttributes.pageLinkSize =
      this.tableAttributes.pageLinkSize ??
      this.defaultTableAttributes.pageLinkSize;
    this.tableAttributes.showFirstLastIcon =
      this.tableAttributes.showFirstLastIcon ??
      this.defaultTableAttributes.showFirstLastIcon;
    if (this.rowsFormControl.value !== this.tableAttributes.rows) {
      this.rowsFormControl.patchValue(this.tableAttributes.rows);
    }
  }

  ngOnDestroy(): void {
    this.destroyed = true;
  }

  handleOnPageChanged(event: PaginatorState): void {
    this.tableAttributes.rows = event.rows || 10;
    this.tableAttributes.first = event.first || 0;
    this.pageChange.emit({
      offset: Number(event.first),
      limit: Number(event.rows),
    });
  }

  displayPerPageTotal(): number {
    return Math.min(
      this.tableAttributes.total,
      this.tableAttributes.first + this.tableAttributes.rows,
    );
  }
}
