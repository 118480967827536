import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, first, from, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserAuthenticationService } from '../../auth/services/user-authentication.service';

/** @todo discuss this with rafiq */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private userAuthenticationService: UserAuthenticationService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.url.startsWith('/api/')) {
      return from(this.userAuthenticationService.getAuthorizationToken()).pipe(
        first(),
        switchMap((idToken) => {
          if (!idToken) {
            return next.handle(req);
          }

          const cloned = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${idToken}`),
          });

          return next.handle(cloned);
        }),
      );
    }

    return next.handle(req);
  }
}
