import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import {
  BillingAddress,
  BillingDocumentLineItem,
  MappedBillingDocument,
} from '../../../accounts/types';

@Component({
  selector: 'rwa-invoice-print',
  templateUrl: './invoice-print.component.html',
  styleUrl: './invoice-print.component.scss',
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.None,
})
export class InvoicePrintComponent implements OnInit {
  @Input() invoice!: any;

  @Input() isCreditNote: boolean;

  @Output() dataLoaded = new EventEmitter();

  zatcaCompleted: boolean;

  info = {
    pages: 0,
    pageHeight: 0,
    freeSpace: 0,
  };

  rewaaInfo = ` <div>شركة رواء التقنية لتقنية المعلومات شركة شخص واحد ذ.م.م</div>
      <div>٣٢٥٩، انس بن مالك، ٨٣٨٤ حي الملقا، ١٣٥٢٢ الرياض </div>
      <div>المملكة العربية السعودية</div>
      <div>رقم السجل التجاري:1010948357 </div>
      <div>رقم التسجيل الضريبي:310188259700003 </div>`;

  watermarkDiv = ` <div class="watermark">
      <div>DRAFT INVOICE</div>
      <div>فاتـــــــــــــــــــــورة مسودّة</div>
    </div>`;

  footerMargin = 200;

  constructor(private datePipe: DatePipe) {}

  ngOnInit(): void {
    if (!this.invoice) return;

    this.setPageHeight();

    this.zatcaCompleted = this.invoice.zatcaStatus === 'Completed';

    if (this.zatcaCompleted) this.watermarkDiv = '';

    // had to add a 200 so the unit tests could pass,
    // but height of T&C should always be larger on platform
    // this.info.pageHeight = Math.max(this.getHeight('T&C'), 200);

    if (this.isCreditNote) {
      const bankTransferDiv = document.getElementById('bank-transfer');
      bankTransferDiv.classList.add('hide');

      const termsAndConditionDiv = document.getElementById('T&C');
      termsAndConditionDiv.classList.add('hide');

      this.info.pages = 0;
      // Credit Note Page
      this.addCreditPage();
      this.addCreditNoteCustomerInfo();
      this.addCreditTotalDue();
      this.addAllLineItems('credit');
      this.updateTotalPageNumbers('credit');
    } else {
      // Tax Invoice Page
      this.addTaxInvoicePage();
      this.addCustomerInfo();
      this.addTotalDue();
      this.addAllLineItems('tax');
      this.updateTotalPageNumbers('tax');

      // Payment Summary Page
      this.info.pages = 0;
      this.addPaymentSummaryPage();
      this.addPaymentSummaryCustomerInfo();
      this.addPaymentSummaryTable();
      this.addPaymentStatus();
      this.updateTotalPageNumbers('payment-summary');
    }

    // get last print-wrapper and remove extra page break
    const pages = document.getElementsByClassName('print-wrapper');
    pages[pages.length - 1].classList.remove('page-break');

    // set all page print wrapper css height to page height
    for (let i = 0; i < pages.length; i++) {
      (pages[i] as HTMLElement).style.height = `${this.info.pageHeight}px`;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.invoice) {
      this.printInvoice();
    }
  }

  setPageHeight(): void {
    const userAgent = navigator.userAgent;
    const platform = navigator.platform;

    const isMac = platform.includes('Mac');
    const isWindows = platform.includes('Win');

    const isChrome =
      userAgent.includes('Chrome') &&
      !userAgent.includes('Edg') &&
      !userAgent.includes('Arc');
    const isArc = userAgent.includes('Arc');
    const isFirefox = userAgent.includes('Firefox');
    const isEdge = userAgent.includes('Edg');

    if (isMac) {
      if (isChrome || isArc || isEdge) {
        this.info.pageHeight = 1080;
      } else if (isFirefox) {
        this.info.pageHeight = 980;
      }
    } else if (isWindows) {
      if (isChrome || isEdge) {
        this.info.pageHeight = 980;
      } else if (isFirefox) {
        this.info.pageHeight = 1080;
      }
    } else {
      this.info.pageHeight = 1080;
    }

    console.log(this.info.pageHeight);
  }

  printInvoice(): void {
    if (this.invoice) {
      // this.barCode = this.generateBarcode(this.invoice.id);
      // this.qrCode = this.getQRCodeDetails(
      //   this.invoice.date,
      //   this.invoice.total,
      //   this.invoice.tax || 0,
      //   this.invoice.isCreditNote,
      // );
      this.dataLoaded.emit();
    }
  }

  addTaxInvoicePage(): void {
    this.info.pages += 1;
    this.info.freeSpace = this.info.pageHeight;

    const div = document.getElementById('invoice-page');
    const printWrapper = `<div
        class="print-wrapper page-break"
        id="p-${this.info.pages}"
      ></div>`;
    div.innerHTML += printWrapper;
    this.addHeader();

    // create div where all the content of the page will go in
    const printWrapperDiv = document.getElementById(`p-${this.info.pages}`);
    const contentDiv = `<ng-template
      ${this.zatcaCompleted ? '' : 'class="body"'}
      id="content-${this.info.pages}"
    >
    ${this.zatcaCompleted ? '' : this.watermarkDiv}
    </ng-template>`;
    printWrapperDiv.innerHTML += contentDiv;

    this.addFooter();
  }

  getHeight(id: string, subtractHeight = true): number {
    const div = document.getElementById(id);
    // const rect = div.getBoundingClientRect();
    // let height = rect.height;
    // height = div.scrollHeight;
    // height = div.offsetHeight;

    const height = div.clientHeight;
    if (subtractHeight) this.info.freeSpace -= height;
    return height;
  }

  addHeader(): void {
    const isB2B = this.invoice.invoice_type === 'B2B';
    const header = `<header
      class="grid pb-3"
      id="header-${this.info.pages}"
    ><div
      class="col-5"
    >
      <div class="fs-22 font-semibold">${isB2B ? 'Tax Invoice' : 'Simplified Tax Invoice'}</div>
      <div class="rw__fs-7 font-normal text-neutral-60">${isB2B ? 'فاتورة ضريبية' : 'فاتوره ضريبية مبسطه '}</div>
    </div>
    <div class="col-2">
      <img
        class="mx-auto"
        src="/assets/images/rewaa-emblem-logo.svg"
        alt=""
      />
    </div>
    <div class="col-5 text-neutral-60 text-right">
      ${this.rewaaInfo}
    </div>
    </header>`;
    const div = document.getElementById(`p-${this.info.pages}`);
    div.innerHTML += header;
    this.getHeight(`header-${this.info.pages}`);
  }

  addFooter(): void {
    const footer = `<footer class="text-neutral-60 pt-3" id="footer-${this.info.pages}">
    <div class="grid align-items-end">
      <div class="col-9">
      ${
        this.zatcaCompleted
          ? `
        <img
        src="/assets/images/invoice-pdf-qr.svg"
        alt=""
        />
        <div class="mt-2">
        This QR code is encoded as per ZATCA's invoicing requirements
        </div>
        <div>
        رمز الإستجابة السريعة مشفّر بحسب متطلبات هيئة الزكاة والضريبة والجمارك
        للفوترة الإلكترونية
        </div>
        `
          : ''
      }
      </div>
      <div class="col-3">
        <div class="text-right">Page ${this.info.pages} of <span class='total-pages'>X</span>
         ${this.zatcaCompleted ? `- ${this.invoice.id}` : ''}</div>
      </div>
    </div>
  </footer>`;
    const div = document.getElementById(`p-${this.info.pages}`);
    div.innerHTML += footer;
    this.getHeight(`footer-${this.info.pages}`);
  }

  addCustomerInfo(): void {
    const customerInfo = `<div
    class="grid"
    id="customer-info-${this.info.pages}"
  >
    <div class="col-3">
      <div class="font-medium">Bill to</div>
      <div class="font-medium text-neutral-60 mb-1">العميل</div>
      <div>${this.invoice.billingAddress.first_name}</div>
      <div>
        ${this.getAddress(this.invoice.billingAddress)}
      </div>
    </div>
    <div class="col-3">
      <div class="flex flex-column gap-2">
        <div>
          <div class="font-medium">Buyer Tax Number</div>
          <div class="font-medium text-neutral-60 mb-1">
            رقم السجل الضريبي للعميل
          </div>
          <div>${this.invoice.vatNumber ?? '--'}</div>
        </div>
        <div>
          <div class="font-medium">Buyer ID: ${this.invoice.additionalIdType ?? '--'}</div>
          <div class="font-medium text-neutral-60 mb-1">
            معرّف العميل: سجل تجاري
          </div>
          <div>${this.invoice.additionalIdValue ?? '--'}</div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="flex flex-column gap-2">
        <div>
          <div class="font-medium">Invoice Number</div>
          <div class="font-medium text-neutral-60 mb-1">رقم الفاتورة</div>
          <div>${this.zatcaCompleted ? this.invoice.id : '--'}</div>
        </div>
        <div>
          <div class="font-medium">Invoice Issue Date</div>
          <div class="font-medium text-neutral-60 mb-1">
            تاريخ إصدار الفاتورة
          </div>
          <div>${this.datePipe.transform(new Date(this.invoice.date * 1000), 'yyyy-MM-d, hh:mm:ss a')}</div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="flex flex-column gap-2 text-right">
          ${
            !this.zatcaCompleted
              ? `<div>
                    <div class="font-medium">Reference No.</div>
                    <div class="font-medium text-neutral-60 mb-1">رقم مرجعي</div>
                    <div>${this.invoice.id}</div>
                  </div>`
              : ''
          }
        <div>
          <div class="font-medium">Supply Date</div>
          <div class="font-medium text-neutral-60 mb-1">تاريخ التسليم</div>
          <div>${this.invoice.date ? this.datePipe.transform(new Date(this.invoice.date * 1000), 'yyyy-MM-d') : '--'}</div>
        </div>
      </div>
    </div>
  </div>`;

    const div = document.getElementById(`content-${this.info.pages}`);
    div.innerHTML += customerInfo;
    this.getHeight(`customer-info-${this.info.pages}`);
  }

  addTotalDue(): void {
    const content = `  <div class="text-right pt-2 border-top-1 border-neutral-20" id='total-due-${this.info.pages}'>
    <div class="font-medium">Total Due</div>
    <div class="font-medium text-neutral-60">إجمالي المستحق</div>
    <div class="flex gap-1 align-items-center justify-content-end">
      <div class="line-height-1">
        <div class="rw__fs-9">SAR</div>
        <div class="rw__fs-11 text-neutral-60">ر.س</div>
      </div>
      <div class="rw__fs-5">${(this.invoice.total / 100).toFixed(2)}</div>
    </div>
  </div>`;
    const div = document.getElementById(`content-${this.info.pages}`);
    div.innerHTML += content;
    this.getHeight(`total-due-${this.info.pages}`);
  }

  addAllLineItems(type: 'tax' | 'credit'): void {
    this.addLineItemTableHeader(type);
    this.invoice.lineItems.forEach((lineItem, index) => {
      this.addSingleLineItem(lineItem, index, type);
    });
    this.addSummary(type);
  }

  addLineItemTableHeader(type: 'tax' | 'credit'): void {
    const tableHeader = `<div class="pb-2 pt-3">
    <table class="print-table print-border-table print-item-table" id="table-header-${type}-${this.info.pages}">
      <thead>
        <tr>
          <th class="text-left">
            <div class="font-medium text-neutral-60">#</div>
          </th>
          <th>
            <div class="font-medium text-left">Item / Description</div>
            <div class="font-medium text-neutral-60 text-left">الوصف / المنتج</div>
          </th>
          <th>
            <div class="font-medium">Price</div>
            <div class="font-medium text-neutral-60">سعر الوحدة</div>
          </th>
          <th>
            <div class="font-medium">Quantity</div>
            <div class="font-medium text-neutral-60">الكمية</div>
          </th>
          <th>
            <div class="font-medium">Discount</div>
            <div class="font-medium text-neutral-60">خصم</div>
          </th>
          <th>
            <div class="font-medium">Taxable Amount</div>
            <div class="font-medium text-neutral-60">المبلغ الخاضع للضريبة</div>
          </th>
          <th>
            <div class="font-medium">VAT</div>
            <div class="font-medium text-neutral-60">القيمة المضافة</div>
          </th>
          <th>
            <div class="font-medium">Amount</div>
            <div class="font-medium text-neutral-60">المجموع</div>
          </th>
        </tr>
      </thead>
      <tbody id="t-body-${type}-${this.info.pages}">
      </tbody>
    </table>
  </div>`;
    let div: HTMLElement | null;
    if (type === 'tax')
      div = document.getElementById(`content-${this.info.pages}`);
    else div = document.getElementById(`content-credit-${this.info.pages}`);

    div.innerHTML += tableHeader;
    this.getHeight(`table-header-${type}-${this.info.pages}`);
  }

  addSingleLineItem(
    lineItem: BillingDocumentLineItem,
    index: number,
    type: 'tax' | 'credit',
  ): void {
    const row = `<tr id='table-row-${type}-${index}'>
          <td class="text-left">
            <div class="font-medium text-neutral-60">${index + 1}</div>
          </td>
          <td class="text-neutral-60 text-left">
            <div class="font-semibold">
              <div>${lineItem.name} (${this.calculatePeriod(lineItem.dateFrom, lineItem.dateTo)})</div>
              <div>اشتراك باقة أساسية (سنة)</div>
            </div>
            <div>${this.getItemFromToTime(lineItem.dateFrom, lineItem.dateTo)}</div>
          </td>
          <td>
            <div>${this.convertToPrice(lineItem.unitAmount)}</div>
          </td>
          <td>
            <div>x${lineItem.quantity}</div>
          </td>
          <td>
            <div>-${this.convertToPrice(lineItem.discountAmount)}</div>
            <div class="text-neutral-60">${((lineItem.discountAmount / (lineItem.unitAmount * lineItem.quantity)) * 100).toFixed(1)}%</div>
          </td>
          <td>
            <div>${this.convertToPrice(lineItem.taxableAmount)}</div>
          </td>
          <td>
            <div>${this.convertToPrice(lineItem.taxAmount)}</div>
            <div class="text-neutral-60">${lineItem.taxRate}%</div>
          </td>
          <td>
            <div>${this.convertToPrice(lineItem.amount + lineItem.taxAmount)}</div>
          </td>
        </tr>`;

    const div = document.getElementById(`t-body-${type}-${this.info.pages}`);
    div.innerHTML += row;
    const rowHeight = this.getHeight(`table-row-${type}-${index}`, false);

    if (rowHeight > this.info.freeSpace - this.footerMargin) {
      div.removeChild(document.getElementById(`table-row-${type}-${index}`));
      if (type === 'tax') this.addTaxInvoicePage();
      else if (type === 'credit') this.addCreditPage();
      this.addLineItemTableHeader(type);
      const newDiv = document.getElementById(
        `t-body-${type}-${this.info.pages}`,
      );
      newDiv.innerHTML += row;
      this.getHeight(`table-row-${type}-${index}`);
    } else {
      this.info.freeSpace -= rowHeight;
    }
  }

  addSummary(type: 'tax' | 'credit'): void {
    const summary = `<div class="grid" id="summary-${type}">
    <div class="col-6">
      <div class="font-medium">Notes</div>
      <div class="font-medium text-neutral-60 mb-1">ملاحظات</div>
      <div>-</div>
    </div>
    <div class="col-6">
      <table class="print-table print-item-table">
        <tbody>
          <tr>
            <td>
              <div class="font-medium">Subtotal</div>
              <div class="font-medium text-neutral-60">
                الإجمالي غير شامل الضريبة
              </div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div>${this.convertToPrice(this.invoice.printingSubtotal)}</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="font-medium">Total Discount</div>
              <div class="font-medium text-neutral-60">إجمالي الخصم</div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div>-${this.convertToPrice(this.invoice.totalDiscount) ?? 0}</div>
              <div class="text-neutral-60">${(((this.invoice.totalDiscount ?? 0) / this.invoice.printingSubtotal) * 100).toFixed(1)}%</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="font-medium">Total Taxable Amount</div>
              <div class="font-medium text-neutral-60">
                إجمالي المبلغ الخاضع للضريبة
              </div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div>${this.convertToPrice(this.invoice.totalTaxableAmount)}</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="font-medium">Total VAT</div>
              <div class="font-medium text-neutral-60">
                إجمالي ضريبة القيمة المضافة
              </div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div>${this.convertToPrice(this.invoice.tax)}</div>
              <div class="text-neutral-60">${this.invoice.lineItems[0].taxRate ?? 15}%</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="font-medium">Total</div>
              <div class="font-medium text-neutral-60">
                الإجمالي شامل الضريبة
              </div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div class="font-medium">${this.convertToPrice(this.invoice.total)}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>`;

    let div: HTMLElement | null;
    if (type === 'tax')
      div = document.getElementById(`content-${this.info.pages}`);
    else div = document.getElementById(`content-credit-${this.info.pages}`);

    div.innerHTML += summary;
    const summaryHeight = this.getHeight(`summary-${type}`, false);

    if (summaryHeight > this.info.freeSpace - this.footerMargin) {
      div.removeChild(document.getElementById(`summary-${type}`));
      if (type === 'tax') this.addTaxInvoicePage();
      else if (type === 'credit') this.addCreditPage();
      this.addSummary(type);
    } else {
      this.info.freeSpace -= summaryHeight;
    }
  }

  updateTotalPageNumbers(type: 'tax' | 'credit' | 'payment-summary'): void {
    let totalPageSpans: HTMLCollectionOf<Element>;
    if (type === 'tax')
      totalPageSpans = document.getElementsByClassName('total-pages');
    else if (type === 'credit')
      totalPageSpans = document.getElementsByClassName('total-credit-pages');
    else if (type === 'payment-summary')
      totalPageSpans = document.getElementsByClassName(
        'total-payment-summary-pages',
      );

    for (let i = 0; i < totalPageSpans.length; i++) {
      const pageCount =
        type === 'tax'
          ? (this.info.pages + 2).toString()
          : this.info.pages.toString();
      totalPageSpans[i].innerHTML = pageCount;
    }
  }

  // ------ Credit Notes Page -----

  addCreditPage(): void {
    this.info.pages += 1;
    this.info.freeSpace = this.info.pageHeight;

    const div = document.getElementById('credit-page');
    const printWrapper = `<div
        class="print-wrapper page-break"
        id="p-credit-${this.info.pages}"
      ></div>`;
    div.innerHTML += printWrapper;
    this.addCreditNoteHeader();

    // create div where all the content of the page will go in
    const printWrapperDiv = document.getElementById(
      `p-credit-${this.info.pages}`,
    );
    const contentDiv = `<ng-template
    ${this.zatcaCompleted ? '' : 'class="body"'}
      id="content-credit-${this.info.pages}"
    > ${this.zatcaCompleted ? '' : this.watermarkDiv}</ng-template>`;
    printWrapperDiv.innerHTML += contentDiv;

    this.addCreditFooter();
  }

  addCreditNoteHeader(): void {
    const header = `<header
      class="grid pb-3"
      id="header-credit-${this.info.pages}"
    ><div
      class="col-5"
    >
      <div class="fs-22 font-semibold">Credit Note</div>
      <div class="rw__fs-7 font-normal text-neutral-60">إشعار دائن</div>
    </div>
    <div class="col-2">
      <img
        class="mx-auto"
        src="/assets/images/rewaa-emblem-logo.svg"
        alt=""
      />
    </div>
    <div class="col-5 text-neutral-60 text-right">
      ${this.rewaaInfo}
    </div>
    </header>`;
    const div = document.getElementById(`p-credit-${this.info.pages}`);
    div.innerHTML += header;
    this.getHeight(`header-credit-${this.info.pages}`);
  }

  addCreditFooter(): void {
    const footer = `<footer class="text-neutral-60 pt-3" id="footer-credit-${this.info.pages}">
    <div class="grid align-items-end">
      <div class="col-9">
        <img
          src="/assets/images/invoice-pdf-qr.svg"
          alt=""
        />
        <div class="mt-2">
          This QR code is encoded as per ZATCA's invoicing requirements
        </div>
        <div>
          رمز الإستجابة السريعة مشفّر بحسب متطلبات هيئة الزكاة والضريبة والجمارك
          للفوترة الإلكترونية
        </div>
      </div>
      <div class="col-3">
        <div class="text-right">Page ${this.info.pages} of <span class='total-credit-pages'>X</span> - ${this.invoice.id}</div>
      </div>
    </div>
  </footer>`;
    const div = document.getElementById(`p-credit-${this.info.pages}`);
    div.innerHTML += footer;
    this.getHeight(`footer-credit-${this.info.pages}`);
  }

  addCreditNoteCustomerInfo(): void {
    const customerInfo = `<div
    class="grid"
    id="customer-info-credit-${this.info.pages}"
  >
    <div class="col-3">
      <div class="font-medium">Bill to</div>
      <div class="font-medium text-neutral-60 mb-1">العميل</div>
      <div>${this.invoice.billingAddress.first_name}</div>
      <div>
        ${this.getAddress(this.invoice.billingAddress)}
      </div>
    </div>
    <div class="col-3">
      <div class="flex flex-column gap-2">
        <div>
          <div class="font-medium">Buyer Tax Number</div>
          <div class="font-medium text-neutral-60 mb-1">
            رقم السجل الضريبي للعميل
          </div>
          <div>${this.invoice.vatNumber ?? '--'}</div>
        </div>
        <div>
          <div class="font-medium">Buyer ID: ${this.invoice.additionalIdType ?? '--'}</div>
          <div class="font-medium text-neutral-60 mb-1">
            معرّف العميل: سجل تجاري
          </div>
          <div>${this.invoice.additionalIdValue ?? '--'}</div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="flex flex-column gap-2">
        <div>
          <div class="font-medium">Credit Note Number</div>
          <div class="font-medium text-neutral-60 mb-1">رقم الفاتورة</div>
          <div>${this.invoice.id}</div>
        </div>
        <div>
          <div class="font-medium">Credit Note Issue Date</div>
          <div class="font-medium text-neutral-60 mb-1">
            تاريخ إصدار الفاتورة
          </div>
          <div>${this.datePipe.transform(new Date(this.invoice.date * 1000), 'yyyy-MM-d, hh:mm:ss a')}</div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="flex flex-column gap-2 text-right">
        <div>
          <div class="font-medium">Invoice Number</div>
          <div class="font-medium text-neutral-60 mb-1">تاريخ التسليم</div>
          <div>${this.invoice.reference_invoice_id}</div>
        </div>
      </div>
    </div>
  </div>`;

    const div = document.getElementById(`content-credit-${this.info.pages}`);
    div.innerHTML += customerInfo;
    this.getHeight(`customer-info-credit-${this.info.pages}`);
  }

  addCreditTotalDue(): void {
    const content = `  <div class="text-right pt-2 border-top-1 border-neutral-20" id='total-credit-due-${this.info.pages}'>
    <div class="font-medium">Total Credit Note Amount</div>
    <div class="font-medium text-neutral-60">إجمالي قيمة إشعار الدائن</div>
    <div class="flex gap-1 align-items-center justify-content-end">
      <div class="line-height-1">
        <div class="rw__fs-9">SAR</div>
        <div class="rw__fs-11 text-neutral-60">ر.س</div>
      </div>
      <div class="rw__fs-5">${this.convertToPrice(this.invoice.total)}</div>
    </div>
  </div>`;
    const div = document.getElementById(`content-credit-${this.info.pages}`);
    div.innerHTML += content;
    this.getHeight(`total-credit-due-${this.info.pages}`);
  }

  // ------ Payment Summary Page -------

  addPaymentSummaryPage(): void {
    this.info.pages += 1;
    this.info.freeSpace = this.info.pageHeight;

    const div = document.getElementById('payment-summary-page');
    const printWrapper = `<div

        class="print-wrapper page-break"
        id="p-payment-summary-${this.info.pages}"
      ></div>`;
    div.innerHTML += printWrapper;
    this.addPaymentSummaryHeader();

    // create div where all the content of the page will go in
    const printWrapperDiv = document.getElementById(
      `p-payment-summary-${this.info.pages}`,
    );
    const contentDiv = `<ng-template
      ${this.zatcaCompleted ? '' : 'class="body"'}
      id="content-payment-summary-${this.info.pages}"
    >
    ${this.watermarkDiv}
      </ng-template>`;
    printWrapperDiv.innerHTML += contentDiv;

    this.addPaymentSummaryFooter();
  }

  addPaymentSummaryHeader(): void {
    const header = `<header
      class="grid pb-3"
      id="header-payment-summary-${this.info.pages}"
    ><div
      class="col-5"
    >
      <div class="fs-22 font-semibold">Payment Summary</div>
      <div class="rw__fs-7 font-normal text-neutral-60">ملخّص المدفوعات</div>
    </div>
    <div class="col-2">
      <img
        class="mx-auto"
        src="/assets/images/rewaa-emblem-logo.svg"
        alt=""
      />
    </div>
    <div class="col-5 text-neutral-60 text-right">
      ${this.rewaaInfo}
    </div>
    </header>`;
    const div = document.getElementById(`p-payment-summary-${this.info.pages}`);
    div.innerHTML += header;
    this.getHeight(`header-payment-summary-${this.info.pages}`);
  }

  addPaymentSummaryFooter(): void {
    const footer = `
        <footer class="text-neutral-60 pt-3" id="footer-payment-summary-${this.info.pages}">
        <div class="grid align-items-end">
          <div class="col-9">
            ${
              this.zatcaCompleted
                ? `
              <img
              src="/assets/images/invoice-pdf-qr.svg"
              alt=""
              />
              <div class="mt-2">
              This QR code is encoded as per ZATCA's invoicing requirements
              </div>
              <div>
              رمز الإستجابة السريعة مشفّر بحسب متطلبات هيئة الزكاة والضريبة والجمارك
              للفوترة الإلكترونية
              </div>
              `
                : ''
            }
          </div>
          <div class="col-3">
            <div class="text-right">Page ${this.info.pages} of <span class='total-payment-summary-pages'>X</span>${this.zatcaCompleted ? `- ${this.invoice.id}` : ''}</div>
          </div>
        </div>
      </footer>`;
    const div = document.getElementById(`p-payment-summary-${this.info.pages}`);
    div.innerHTML += footer;
    this.getHeight(`footer-payment-summary-${this.info.pages}`);
  }

  addPaymentSummaryCustomerInfo(): void {
    const customerInfo = `
      <div class="grid"
      id="customer-info-payment-summary-${this.info.pages}"
      >
    <div class="col-3">
      <div class="font-medium">Customer</div>
      <div class="font-medium text-neutral-60 mb-1">العميل</div>
      <div>${this.invoice.billingAddress.first_name}</div>
      <div>
      ${this.getAddress(this.invoice.billingAddress)}
      </div>
    </div>
    <div class="col-3">
      <div class="flex flex-column gap-2">
        <div>
          <div class="font-medium">Buyer Tax Number</div>
          <div class="font-medium text-neutral-60 mb-1">
            رقم السجل الضريبي للعميل
          </div>
          <div>${this.invoice.vatNumber ?? '--'}</div>
        </div>
        <div>
          <div class="font-medium">Buyer ID: ${this.invoice.additionalIdType ?? '--'}</div>
          <div class="font-medium text-neutral-60 mb-1">
            معرّف العميل: سجل تجاري
          </div>
          <div>${this.invoice.additionalIdValue ?? '--'}</div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="flex flex-column gap-2">
        <div>
          <div class="font-medium">Invoice Number</div>
          <div class="font-medium text-neutral-60 mb-1">رقم الفاتورة</div>
          <div>${this.invoice.id}</div>
        </div>
      </div>
    </div>
    <div class="col-3 text-right">
      <div class="flex flex-column gap-2">
        <div>
          <div class="font-medium">Last Updated</div>
          <div class="font-medium text-neutral-60 mb-1">تاريخ آخر تحديث</div>
          <div>${this.datePipe.transform(new Date(this.invoice.lastUpdated * 1000), 'yyyy-MM-d, hh:mm:ss a')}</div>
        </div>
      </div>
    </div>
  </div>`;

    const div = document.getElementById(
      `content-payment-summary-${this.info.pages}`,
    );
    div.innerHTML += customerInfo;
    this.getHeight(`customer-info-payment-summary-${this.info.pages}`);
  }

  addPaymentSummaryTableHeader(): void {
    const tableHeader = `  <div class="pb-2 pt-6">
    <table class="print-table print-border-table payment-table">
      <thead>
        <tr>
          <th class="text-left">
            <div class="font-medium">Transaction Date</div>
            <div class="font-medium text-neutral-60">تاريخ العملية</div>
          </th>
          <th class="text-left">
            <div class="font-medium">Payment Method</div>
            <div class="font-medium text-neutral-60">طريقة الدفع</div>
          </th>
          <th class="text-left">
            <div class="font-medium">Reference No.</div>
            <div class="font-medium text-neutral-60">الرقم المرجعي</div>
          </th>
          <th>
            <div class="font-medium">Amount Paid</div>
            <div class="font-medium text-neutral-60">المبلغ المدفوع</div>
          </th>
        </tr>
      </thead>
      <tbody id="t-body-payment-summary-${this.info.pages}">
      </tbody>
    </table>
  </div>`;

    const div = document.getElementById(
      `content-payment-summary-${this.info.pages}`,
    );
    div.innerHTML += tableHeader;
    this.getHeight(`t-body-payment-summary-${this.info.pages}`);
  }

  addPaymentSummaryTableRow(txnData: any, index: number): void {
    const row = `<tr id="table-row-payment-summary-${index}">
          <td class="text-left">
            <div class="font-semibold text-neutral-60">${this.datePipe.transform(new Date(txnData.txn_date * 1000), 'dd MM yyyy')}</div>
          </td>
          <td class="text-left">
            <div>${txnData.paymentMethod ?? ''}</div>
            <div class="text-neutral-60">${txnData.bankName ?? ''}</div>
          </td>
          <td class="text-left">
            <div>${txnData.txn_id}</div>
          </td>
          <td>
            <div class="font-semibold">${this.convertToPrice(txnData.txn_amount)}</div>
          </td>
        </tr>`;
    const div = document.getElementById(
      `t-body-payment-summary-${this.info.pages}`,
    );
    div.innerHTML += row;
    const rowHeight = this.getHeight(
      `table-row-payment-summary-${index}`,
      false,
    );

    if (rowHeight > this.info.freeSpace - this.footerMargin) {
      div.removeChild(
        document.getElementById(`table-row-payment-summary-${index}`),
      );
      this.addPaymentSummaryPage();
      this.addPaymentSummaryTableHeader();
      const newDiv = document.getElementById(
        `t-body-payment-summary-${this.info.pages}`,
      );
      newDiv.innerHTML += row;
      this.getHeight(`table-row-payment-summary-${index}`);
    } else {
      this.info.freeSpace -= rowHeight;
    }
  }

  addSummaryForPaymentSummaryPage(): void {
    const summary = `
      <div class="grid" id="summary-payment-summary">
    <div class="col-6">
      <div class="font-medium">Notes</div>
      <div class="font-medium text-neutral-60 mb-1">ملاحظات</div>
      <div>-</div>
    </div>
    <div class="col-6">
      <table class="print-table print-item-table">
        <tbody>
          <tr>
            <td>
              <div class="font-medium">Invoice Total</div>
              <div class="font-medium text-neutral-60">
                إجمالي الفاتورة شامل الضريبة
              </div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div>${this.convertToPrice(this.invoice.total)}</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="font-medium">Total Collected Amount</div>
              <div class="font-medium text-neutral-60">
                إجمالي المبالغ المحصّلة
              </div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div>-${this.convertToPrice(this.invoice.totalCollectedAmount)}</div>
            </td>
          </tr>
          <tr class="fs-7 text-neutral-60">
            <td class="pt-0">
              <div>Total Amount Paid</div>
              <div class="font-medium">إجمالي المبالغ المدفوعة</div>
            </td>
            <td class="pt-0">
              <div>SAR</div>
              <div>ر.س</div>
            </td>
            <td class="pt-0">
              <div>${this.convertToPrice(this.invoice.amountPaid)}</div>
            </td>
          </tr>
          <tr class="fs-7 text-neutral-60">
            <td class="pt-0">
              <div>Customer Credit</div>
              <div>رصيد العميل</div>
            </td>
            <td class="pt-0">
              <div>SAR</div>
              <div>ر.س</div>
            </td>
            <td class="pt-0">
              <div>${this.convertToPrice(this.invoice.creditsApplied)}</div>
            </td>
          </tr>
          <tr class="border-top-1 border-neutral-20">
            <td>
              <div class="font-medium">Total Due</div>
              <div class="font-medium text-neutral-60">إجمالي المستحق</div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div class="font-semibold">${this.convertToPrice(this.invoice.dueAmount)}</div>
            </td>
          </tr>
          ${
            this.invoice.excessBalance > 0
              ? `          <tr>
            <td>
              <div class="font-medium">Excess Balance</div>
              <div class="font-medium text-neutral-60">رصيد زائد</div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div class="font-medium">${this.convertToPrice(this.invoice.excessBalance)}</div>
            </td>
          </tr>`
              : ''
          }
          

        </tbody>
      </table>
    </div>
  </div>`;

    const div = document.getElementById(
      `content-payment-summary-${this.info.pages}`,
    );

    div.innerHTML += summary;
    const summaryHeight = this.getHeight(`summary-payment-summary`, false);

    if (summaryHeight > this.info.freeSpace - this.footerMargin) {
      div.removeChild(document.getElementById(`summary-payment-summary`));
      this.addPaymentSummaryPage();
      this.addSummaryForPaymentSummaryPage();
    } else {
      this.info.freeSpace -= summaryHeight;
    }
  }

  addPaymentSummaryTable(): void {
    this.addPaymentSummaryTableHeader();
    this.invoice.txnData.forEach((txnData, index) => {
      this.addPaymentSummaryTableRow(txnData, index);
    });
    this.addSummaryForPaymentSummaryPage();
  }

  addPaymentStatus(): void {
    const status =
      this.invoice.dueAmount > 0
        ? `<div class="flex gap-4 pt-2 justify-content-between" id="payment-status-${this.info.pages}">
      <div>
        <div class="font-medium">Payment Status</div>
        <div class="font-medium text-neutral-60">حالة الدفع</div>
        <div class="text-red-60 rw__fs-9 py-2">Partially Paid مدفوع جزئياً</div>
      </div>
      <div class="text-right">
        <div class="font-medium">Total Due</div>
        <div class="font-medium text-neutral-60">إجمالي المستحق</div>
        <div
          class="flex gap-1 align-items-center justify-content-end text-red-60"
        >
          <div class="line-height-1">
            <div class="rw__fs-9">SAR</div>
            <div class="rw__fs-11">ر.س</div>
          </div>
          <div class="rw__fs-5">${this.convertToPrice(this.invoice.dueAmount)}</div>
        </div>
      </div>
    </div>`
        : `
        <div class="flex gap-4 pt-2 justify-content-between" id="payment-status-${this.info.pages}">
      <div>
        <div class="font-medium">Payment Status</div>
        <div class="font-medium text-neutral-60">حالة الدفع</div>
        <div class="text-green-60 rw__fs-9 py-2">
          Paid In Full مدفوعة بالكامل
        </div>
      </div>
      <div class="text-right">
        <div class="font-medium">Total Due</div>
        <div class="font-medium text-neutral-60">إجمالي المستحق</div>
        <div class="flex gap-1 align-items-center justify-content-end">
          <div class="line-height-1">
            <div class="rw__fs-9">SAR</div>
            <div class="rw__fs-11 text-neutral-60">ر.س</div>
          </div>
          <div class="rw__fs-5">00</div>
        </div>
      </div>
    </div>`;

    const div = document.getElementById(
      `content-payment-summary-${this.info.pages}`,
    );

    div.innerHTML += status;
    const paymentStatusHeight = this.getHeight(
      `payment-status-${this.info.pages}`,
      false,
    );

    if (paymentStatusHeight > this.info.freeSpace - this.footerMargin) {
      div.removeChild(
        document.getElementById(`payment-status-${this.info.pages}`),
      );
      this.addPaymentSummaryPage();
      this.addPaymentStatus();
    } else {
      this.info.freeSpace -= paymentStatusHeight;
    }
  }

  // util functions
  convertToPrice(amount: number): string {
    return (amount / 100).toFixed(2);
  }

  getItemFromToTime(dateFrom: number, dateTo: number): string {
    const from = this.datePipe.transform(dateFrom * 1000, 'dd MMM yyyy');
    const to = this.datePipe.transform(dateTo * 1000, 'dd MMM yyyy');
    return `${from}-${to}`;
  }

  calculatePeriod(dateFrom: number, dateTo: number): string {
    const from = new Date(dateFrom * 1000);
    const to = new Date(dateTo * 1000);

    let diffYears = to.getFullYear() - from.getFullYear();
    let diffMonths = to.getMonth() - from.getMonth();

    if (diffMonths < 0) {
      diffYears -= 1;
      diffMonths += 12; // Add 12 months to get positive value
    }

    if (diffYears === 0) {
      return `${diffMonths} month${diffMonths > 1 ? 's' : ''}`;
    }

    let result = '';

    if (diffYears > 0) {
      result += `${diffYears} year${diffYears > 1 ? 's' : ''}`;
    }

    if (diffMonths > 0) {
      if (result) result += ' and ';
      result += `${diffMonths} month${diffMonths > 1 ? 's' : ''}`;
    }

    return result;
  }

  getAddress(billingAddress: BillingAddress): string {
    return `${billingAddress.zip ?? ''} ${billingAddress.line1 ?? ''} 
    ${billingAddress.line2 ?? ''} ${billingAddress.line3 ?? ''} ${billingAddress.city ?? ''}, ${billingAddress.state ?? ''}, ${billingAddress.country ?? ''}, ${billingAddress.country ?? ''}`;
  }
}
