import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zatcaStatus',
})
export class ZatcaStatusPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'Completed':
        return 'paid';
      case 'Pending':
        return 'in_progress';
      case 'WaitingOnStatus':
        return 'in_progress';
      case 'Rejected':
        return 'payment_due';
      case 'NotSubmitted':
        return 'payment_due';
      default:
        return 'payment_due';
    }
  }
}
