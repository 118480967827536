import { Injectable } from '@angular/core';
import { Amplify } from 'aws-amplify';
import {
  signOut,
  signInWithRedirect,
  fetchAuthSession,
  getCurrentUser,
  fetchUserAttributes,
  FetchUserAttributesOutput,
  AuthUser,
} from 'aws-amplify/auth';
import { environment } from '../../../environments/environment';
import { SessionStorageService } from '../../shared/services/session-storage.service';
import { SessionStorageKey } from '../../shared/constants/constants';

@Injectable()
export class UserAuthenticationService {
  constructor(private sessionStorageService: SessionStorageService) {
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: environment.userPoolId,
          userPoolClientId: environment.userPoolClientId,
          loginWith: {
            oauth: {
              domain: environment.cognitoDomain,
              scopes: environment.scopes,
              redirectSignIn: environment.redirectSignIn,
              redirectSignOut: environment.redirectSignOut,
              responseType: environment.responseType as 'token' | 'code',
            },
          },
        },
      },
    });
  }

  async signIn(
    provider: 'Amazon' | 'Apple' | 'Facebook' | 'Google',
  ): Promise<void> {
    this.sessionStorageService.setItem(SessionStorageKey.LoginInProcess, true);
    await signInWithRedirect({ provider });
  }

  async signOut(): Promise<void> {
    await signOut();
  }

  async getCurrentUser(): Promise<AuthUser> {
    return getCurrentUser();
  }

  async fetchUserAttributes(): Promise<FetchUserAttributesOutput> {
    return fetchUserAttributes();
  }

  async getAuthorizationToken(): Promise<string | undefined> {
    const session = await fetchAuthSession();
    return session.tokens?.idToken?.toString();
  }

  async isAuthenticated(): Promise<boolean> {
    try {
      await getCurrentUser();
      return true;
    } catch (e) {
      return false;
    }
  }

  async logout(): Promise<void> {
    this.sessionStorageService.clear();
    await signOut();
  }
}
