<div class="rw__invoice rw__fs-10">
    <div class="rw__invoice-head mb-2">
      <div class="p-2">
        <img
          src="../../../../assets/images/rewaa_logo.svg"
          alt="Rewaa Logo"
          width="119"
        />
      </div>
      <div class="flex align-items-start pt-2 mb-2">
        <div class="flex flex-column flex-1 m-0 pb-2 px-2">
          <div class=" flex align-items-center pb-3 gap-2 fw-500">
            <span class="text-grey-80">{{ 'Rewaa' }}
              <span class="text-grey-80 me-1">{{' | '}}</span>
              @if (invoice.type === 'invoice'){
                <span class="text-grey-60">{{ 'Taxable Invoice' }}</span>
              }
              @else if(invoice.type === 'credit_note'){
                <span class="text-grey-60">{{ 'Credit Note' }}</span>
              }
            </span>
            @if (invoice.type === 'invoice'){
              <span class="text-grey-80 arabic-font">{{ 'فاتورة ضريبة' }}</span>
            }
            @else if(invoice.type === 'credit_note'){
              <span class="text-grey-80 arabic-font">{{ 'إشعار دائن' }}</span>
            }
          </div>
          <div class="flex justify-content-between pb-3">
            <div class="flex align-items-center gap-2">
              <span class="text-grey-60 fw-400">{{ 'Invoice Date' }}</span>
              <span class="text-grey-80 fw-500 arabic-font"> {{ 'تاريخ الفاتورة' }}</span>
            </div>
            <span class="text-grey-60 fw-400">{{
              invoice && invoice.date * 1000 | date : 'h:mm a d/M/yyy'
            }}</span>
          </div>
          <div class="flex justify-content-between pb-3">
            <div class="flex align-items-center gap-2">
              <span class="text-grey-60 fw-400">{{ 'Invoice Type' }}</span>
              <span class="text-grey-80 fw-500 arabic-font">
                {{ 'نوع الفاتورة' }}
              </span>
            </div>
            <span class="text-grey-60 fw-400">{{ invoice.receiptType }}</span>
          </div>
          <div class="flex justify-content-between pb-3">
            <div class="flex align-items-center gap-2">
              <span class="text-grey-60 fw-400">{{ 'Customer Name' }}</span>
              <span class="text-grey-80 fw-500 arabic-font">
                {{ 'اسم العميل' }}
              </span>
            </div>
            <span class="text-grey-60 fw-400">{{
              invoice.billingAddress?.company || ''
            }}</span>
          </div>
           @if (invoice.additionalIdType){
          <div class="flex justify-content-between pb-3">
            <div class="flex align-items-center gap-2">
              <span class="text-grey-60 fw-400">{{ 'ID Type' }}</span>
              <span class="text-grey-80 fw-500 arabic-font">
                {{ 'نوع المعرف' }}
              </span>
            </div>
            <span class="text-grey-60 fw-400">{{ additionalIdentifiersTranslation[invoice.additionalIdType] || ''}}</span>
          </div>
          }
          @if (invoice.additionalIdValue){
            <div class="flex justify-content-between pb-3">
            <div class="flex align-items-center gap-2">
              <span class="text-grey-60 fw-400">{{ 'ID Number' }}</span>
              <span class="text-grey-80 fw-500 arabic-font">
                {{ 'رقم المعرف' }}
              </span>
            </div>
            <span class="text-grey-60 fw-400">{{ invoice.additionalIdValue || '' }}</span>
          </div>
          }
          <div class="flex justify-content-between" >
            <div class="flex align-items-center gap-2">
              <span class="text-grey-60 fw-400">{{ 'Customer VAT Number' }}</span>
              <span class="text-grey-80 fw-500 arabic-font">
                {{ 'الرقم الضريبي للعميل' }}
              </span>
            </div>
            <span class="text-grey-60 fw-400">{{ invoice.vatNumber }}</span>
          </div>
          @if (invoice.type === 'credit_note'){
          <div class="flex justify-content-between credit-note-margin" >
            <div class="flex align-items-center gap-2">
              <span class="text-grey-60 fw-400">{{ 'Credit Note Number' }}</span>
              <span class="text-grey-80 fw-500 arabic-font">
                {{ 'رقم إشعار دائن' }}
              </span>
            </div>
            <span class="text-grey-60 fw-400">{{ invoice.id }}</span>
          </div>
          <div class="flex justify-content-between credit-note-margin" >
            <div class="flex align-items-center gap-2">
              <span class="text-grey-60 fw-400">{{ 'Credit Note Type' }}</span>
              <span class="text-grey-80 fw-500 arabic-font">
                {{ 'نوع إشعار دائن' }}
              </span>
            </div>
            <span class="text-grey-60 fw-400">{{ translationPrefix + "." + invoice.credit_note_type | translate }}</span>
          </div>
        }
        </div>
        <div class="flex flex-column flex-1 m-0 pb-2 pl-4 pr-2">
          <div class="flex justify-content-between pb-3">
            <div class="flex align-items-center gap-2">
              <span class="text-grey-60 fw-400">{{ 'Sales Type' }}</span>
              <span class="text-grey-80 fw-500 arabic-font">نوع البيع</span>
            </div>
            <span class="text-grey-60 fw-400">{{ invoice.saleType }}</span>
          </div>
          <div class="flex justify-content-between pb-3">
            <div class="flex align-items-center gap-2">
              <span class=" text-grey-60 fw-400">{{ 'Invoice Number' }}</span>
              <span class=" text-grey-80 fw-500 arabic-font">
                {{ 'رقم فاتورة البيع' }}
              </span>
            </div>
            @if(invoice.type === 'credit_note'){
              <span class=" text-grey-60 fw-400">{{ invoice.reference_invoice_id }}</span>
            }
            @else if(invoice.type === 'invoice'){
              <span class=" text-grey-60 fw-400">{{ invoice.id }}</span>
            }
          </div>
          <div class="flex justify-content-between pb-3">
            <div class="flex align-items-center gap-2">
              <span class=" text-grey-60 fw-400">{{ 'VAT Number' }}</span>
              <span class=" text-grey-80 fw-500 arabic-font">
                {{ 'الرقم الضريبي' }}
              </span>
            </div>
            <span class=" text-grey-60 fw-400">{{ invoice.companyVat }}</span>
          </div>
          <div class="flex justify-content-between pb-3">
            <div class="flex align-items-center gap-2">
              <span class=" text-grey-60 fw-400">{{ 'Date of Supply' }}</span>
              <span class=" text-grey-80 fw-500 arabic-font">
                {{ 'تاريخ التوريد' }}
              </span>
            </div>
            <span class=" text-grey-60 fw-400">{{
              invoice.date * 1000 | date : 'd/M/yyy'
            }}</span>
          </div>
          <div class="flex justify-content-between gap-3">
            <div class="flex align-items-center gap-2">
              <span class="text-grey-60 fw-400">
                {{ 'Customer Location Address' }}
              </span>
              <span class="text-grey-80 fw-500 arabic-font">
                {{ 'عنوان العميل' }}
              </span>
            </div>
            <span class="text-grey-60 fw-400 align-items-end">{{ getAddress(invoice.billingAddress)  }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="rw__invoice-content px-2 pt-2 mb-4 border-top-1">
      <ng-container *ngFor="let item of invoice.lineItems; let i = index">
        <div class="flex pb-2 fw-400">
          <div class="flex flex-column flex-1 align-items-start m-0">
            <ng-container *ngIf="i === 0" >
              <span class=" text-grey-80 fw-500 arabic-font">{{ 'الكمية' }}</span>
              <span class=" text-grey-60 fw-400 pb-3">{{ 'Quantity' }}</span>
            </ng-container>
            <span class=" text-grey-80 fw-400">{{ invoice.negativeSymbol }}{{ item.quantity }}x</span>
          </div>
          <div class="flex flex-column flex-1 m-0  align-items-start">
            <ng-container *ngIf="i === 0" >
              <span class=" text-grey-80 fw-500 arabic-font">{{ ' اسم المنتج ' }}</span>
              <span class=" text-grey-60 fw-400 pb-3">{{ 'Product Name' }}</span>
            </ng-container>
            <span class="text-grey-80 fw-400">{{ invoice.lineItems[i].name }}</span>
          </div>
          <div class="flex flex-column flex-1 m-0 align-items-end text-end">
            <ng-container *ngIf="i === 0" >
              <span class=" text-grey-80 fw-500 arabic-font">
                {{ 'سعر الوحدة' }}
              </span>
              <span class=" text-grey-60 fw-400 pb-3">{{ 'Unit Price' }}</span>
            </ng-container>

            <span class=" text-grey-60 fw-400"
              >{{ invoice.negativeSymbol
              }}{{
                invoice.lineItems[i].unitAmount / 100 | number : '1.2-2'
              }}</span
            >
          </div>
          <div class="flex flex-column flex-1 align-items-end m-0 text-end">
            <ng-container *ngIf="i === 0" >
              <span class=" text-grey-80 fw-500 arabic-font">
                {{ 'الخصم' }}
              </span>
              <span class=" text-grey-60 fw-400 pb-3">{{ 'Discount' }}</span>
            </ng-container>

            <span class=" text-grey-60 fw-400"
              >{{ invoice.negativeSymbol
              }}{{
                invoice.lineItems[i].discountAmount / 100 | number : '1.2-2'
              }}
              ({{ calculateDiscountPercentage(invoice.lineItems[i]) }}%)</span
            >
          </div>
          <div class="flex flex-column flex-1 align-items-end m-0 text-end">
            <ng-container *ngIf="i === 0" >
              <span class="text-grey-80 fw-500 arabic-font">
                {{ 'المجموع الفرعي' }}
              </span>
              <span class="text-grey-60 fw-400">{{ 'Total' }}</span>
              <span class="text-grey-60 fw-400 pb-1">{{ 'Tax Exclusive' }}</span>
            </ng-container>

            <span class=" text-grey-60 fw-400"
              >{{ invoice.negativeSymbol
              }}{{
                (invoice.lineItems[i].amount- invoice.lineItems[i].discountAmount) / 100  | number : '1.2-2'
              }}</span
            >
          </div>
          <div class="flex flex-column flex-1 align-items-end m-0 text-end">
            <ng-container *ngIf="i === 0" >
              <span class=" text-grey-80 fw-500 arabic-font">الضريبة</span>
              <span class=" text-grey-60 fw-400 pb-2">Tax</span>
            </ng-container>
            <span class=" text-grey-60 fw-400"
              >{{ invoice.negativeSymbol
              }}{{ invoice.lineItems[i].taxAmount / 100 | number : '1.2-2' }} <span class=" text-grey-60 fw-400">({{
                invoice.lineItems[i].taxRate
              }}%)</span></span
            >
          </div>
          <div class="flex flex-column flex-1 align-items-end m-0 text-end">
            <ng-container *ngIf="i === 0" >
              <span class=" text-grey-80 fw-500 arabic-font">الإجمالي</span>
              <span class=" text-grey-60 fw-400 pb-2">Total</span>
            </ng-container>
            <span class=" text-grey-60 fw-400"
              >{{ invoice.negativeSymbol }}
              {{
                (invoice.lineItems[i].amount + invoice.lineItems[i].taxAmount - invoice.lineItems[i].discountAmount) /
                  100 | number : '1.2-2'
              }}</span
            >
          </div>
        </div>
      </ng-container>
      <div class="flex">
        <div class="flex-1 m-0"></div>
        <div class="border-top-1 flex flex-column flex-1 m-0">
          <div class="flex justify-content-between py-2 fw-400">
            <div class="flex gap-2">
              <span class=" text-grey-60 fw-400">{{ 'Total Items' }}</span>
              <span class=" text-grey-60 fw-500 arabic-font">{{ 'مجموع المنتجات' }}</span>
            </div>
            <span class=" text-grey-80 fw-400">{{ invoice.lineItems.length }}</span>
          </div>
          <div class="border-top-1 flex justify-content-between py-2">
            <div class="flex flex-column fw-400">
              <span class=" text-grey-60 fw-400">{{ 'Sub Total (Tax Exclusive)' }} </span>
              <span class=" text-grey-60 fw-500 arabic-font">
                {{ 'الإجمالي غير شامل للضريبة' }}
              </span>
            </div>
            <span class=" text-grey-80 fw-400"
              >{{ invoice.negativeSymbol
              }}{{ invoice.subTotal / 100 | number : '1.2-2' }} SAR</span
            >
          </div>
          <div class="flex justify-content-between pb-2">
            <div class="flex flex-column fw-400">
              <span class=" text-grey-60 fw-400">{{ 'Total Discounts' }}</span>
              <span class=" text-grey-60 fw-500 arabic-font">
                {{ 'إجمالي الخصم' }}
              </span>
            </div>
            <span class=" text-grey-80 fw-400"
              >{{ invoice.negativeSymbol
              }}{{ (invoice.discount || 0) / 100 | number : '1.2-2' }}SAR</span
            >
          </div>
          <div class="flex justify-content-between pb-2">
            <div class="flex flex-column fw-400">
              <span class=" text-grey-60 fw-400">{{ 'Total Tax' }}</span>
              <span class=" text-grey-60 fw-500 arabic-font">
                {{ 'إجمالي الضريبة' }}
              </span>
            </div>
            <span class=" text-grey-80 fw-400"
              >{{ invoice.negativeSymbol
              }}{{ invoice.tax / 100 | number : '1.2-2' }} SAR</span
            >
          </div>
          <div class="flex justify-content-between pb-2 text-grey-60 fw-400">
            <div class="flex flex-column small-font font-grey px-2">
              <small>{{ 'Value Added tax (15%)' }} </small>
              <small class="arabic-font">
                {{ '(15%) ضريبة القيمة المضافة' }}
              </small>
            </div>
            <span
              >{{ invoice.negativeSymbol
              }}{{ invoice.tax / 100 | number : '1.2-2' }} SAR</span
            >
          </div>
          <div class="flex justify-content-between pb-2 fw-400">
            <div class="flex flex-column">
              <div class="flex">
                <span class=" text-grey-80 fw-400">{{ 'Total' }}</span>
                <span class=" text-grey-60 fw-400 ms-1">{{ '(Tax Inclusive)' }}</span>
              </div>
              <span class=" text-grey-80 fw-500 arabic-font">
                {{ 'الإجمالي شامل للضريبة' }}
              </span>
            </div>
            <span class=" text-grey-80 fw-400">
              {{ invoice.negativeSymbol
              }}{{ ((invoice.total || 0) / 100) | number : '1.2-2' }} SAR</span
            >
          </div>

          <div *ngIf="invoice.isCreditNote">
            <div class="flex justify-content-between fw-400">
              <div class="flex flex-column">
                <span class=" text-grey-60 fw-400">{{ 'Refunded Amount' }} </span>
                <span class=" text-grey-60 fw-500 arabic-font">
                  {{ 'القيمة المدفوعة' }}
                </span>
              </div>
              <span class=" text-grey-80 fw-400"
                >{{ (invoice.total  || 0) / 100 | number : '1.2-2' }} SAR</span
              >
            </div>
          </div>

          <!-- apply only when there is credit applied -->
          <div *ngIf="invoice.creditsApplied">
            <div class="flex justify-content-between">
              <div class="flex flex-column">
                <span class=" text-grey-60 fw-400">{{ 'Credit Amount' }}</span>
                <span class=" text-grey-60 fw-500 arabic-font">المبلغ المحصل</span>
              </div>
              <span class=" text-grey-80 fw-400"
                >{{ invoice.creditsApplied / 100 | number : '1.2-2' }} SAR</span
              >
            </div>
          </div>

          <!-- apply onle of adjustment row -->
          <div *ngIf="invoice.amountAdjusted">
            <div class="flex justify-content-between">
              <div class="flex flex-column">
                <span class=" text-grey-60 fw-400">Adjustment </span>
                <span class=" text-grey-60 fw-500 arabic-font">موازنة/ تسوية</span>
              </div>
              <span class=" text-grey-80 fw-400"
                >-{{ invoice.amountAdjusted / 100 | number : '1.2-2' }}SAR</span
              >
            </div>
          </div>

          <div
            *ngIf="!invoice.isCreditNote"
            class="border-top-1 py-2"
          >
            <div *ngIf="!invoice.isCreditNote">
              <div class="flex justify-content-between">
                <div class="flex flex-column">
                  <span class=" text-grey-60 fw-400">{{ 'Net Amount' }} </span>
                  <span class=" text-grey-60 fw-500 arabic-font">{{ 'المبلغ الصافي' }}</span>
                </div>
                <span class=" text-grey-80 fw-400"
                  >{{ invoice.amountPaid / 100 | number : '1.2-2' }} SAR</span
                >
              </div>
            </div>
          </div>

          <div *ngIf="!invoice.isCreditNote">
            <div class="flex justify-content-between pb-3">
              <div class="flex flex-column">
                <span class=" text-grey-60 fw-400">{{ 'Payment Method (Card)' }} </span>
                <span class=" text-grey-60 fw-500 arabic-font">{{ '(بطاقة) طريقة الدفع' }}</span>
              </div>
              <span class=" text-grey-80 fw-400"
                >{{ invoice.amountPaid / 100 | number : '1.2-2' }} SAR</span
              >
            </div>
          </div>

          <!-- <div *ngIf="!invoice.isCreditNote">
            <div class="flex font-grey justify-content-between pb-3 fw-400">
              <div class="flex flex-column">
                <span class="fw-400">{{ 'Payment Reference Number' }}</span>
                <span class="arabic-font fw-400">{{ 'ايصال الدفع' }}</span>
              </div>
              <span>{{ invoice.paymentReferenceNumber }}</span>
            </div>
          </div> -->

          <div *ngIf="!invoice.isCreditNote">
            <div class="flex justify-content-between fw-400">
              <div class="flex flex-column">
                <span class=" text-grey-60 fw-400">{{ 'Debit Amount' }} </span>
                <span class=" text-grey-60 fw-500 arabic-font">{{ 'المبلغ المستحق من العميل' }}</span>
              </div>
              <span class=" text-grey-80 fw-400">{{ invoice.dueAmount / 100 | number : '1.2-2' }}  SAR</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="rw__invoice-footer flex justify-content-between border-top-1 pt-1 pb-1 px-2"
    >
      <div class="w-25"></div>
      <div class="flex flex-column text-center w-50">
        <div class="pb-3">
          <img
            id="barcode"
            [src]="barCode"
            width="200"
          />
        </div>
        <span class=" text-grey-60 fw-400">3259 Anas Ibn Malik Rd, Al Malqa, Riyadh 13522</span>
        <span class=" text-grey-60 fw-400">Saudi Arabia</span>
        <span class=" text-grey-80 fw-400 pt-3">2 weeks exchange refund upon approval</span>
        <span class=" text-grey-80 fw-400">Thank You</span>
      </div>
      <div class="w-25 text-right">
        <img
          id="qr-code"
          [src]="qrCode"
        />
      </div>
    </div>
  </div>
