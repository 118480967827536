// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const composeServiceApi = (transferHandler, serializer, deserializer, defaultConfig) => {
  return async (config, input) => {
    const resolvedConfig = {
      ...defaultConfig,
      ...config
    };
    // We may want to allow different endpoints from given config(other than region) and input.
    // Currently S3 supports additional `useAccelerateEndpoint` option to use accelerate endpoint.
    const endpoint = await resolvedConfig.endpointResolver(resolvedConfig, input);
    // Unlike AWS SDK clients, a serializer should NOT populate the `host` or `content-length` headers.
    // Both of these headers are prohibited per Spec(https://developer.mozilla.org/en-US/docs/Glossary/Forbidden_header_name).
    // They will be populated automatically by browser, or node-fetch polyfill.
    const request = await serializer(input, endpoint);
    const response = await transferHandler(request, {
      ...resolvedConfig
    });
    return await deserializer(response);
  };
};
export { composeServiceApi };
