import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable()
export class CustomToastService {
  constructor(private messageService: MessageService) {}

  success(message: string, header: string = '', key?: string): void {
    this.messageService.add({
      key,
      severity: 'success',
      summary: header,
      detail: message,
    });
  }

  error(message: string, header: string = 'Error', key?: string): void {
    this.messageService.add({
      key,
      severity: 'error',
      summary: header,
      detail: message,
    });
  }

  info(message: string, header: string = 'Info  '): void {
    this.messageService.add({
      severity: 'info',
      summary: header,
      detail: message,
    });
  }

  warning(message: string, header: string = 'Warning', key?: string): void {
    this.messageService.add({
      severity: 'warning',
      summary: header,
      detail: message,
      key,
    });
  }
}
