// eslint-disable-next-line import/no-cycle
import { MappedBillingDocument } from '../accounts/types';

export type ZatcaMessageCategory =
  | 'INVOICE_ERRORS' // invoice hash or invoice missing
  | 'XSD_SCHEMA_ERROR' // XML UBL 2.1 standards
  | 'EN_KSA_ERRORS' // XML rules error
  | 'Authentication-Errors' // Certificate unauthorized
  | 'HTTP-Errors' // 500
  | 'QR_CODE_ERROR';

export type ZatcaMessageType = 'INFO' | 'ERROR' | 'WARNING';

export interface ZatcaMessage {
  type: ZatcaMessageType;
  code: string;
  status: string;
  category: ZatcaMessageCategory;
  message: string;
}

export enum InvoiceZatcaStatusConstant {
  Pending = 'Pending',
  Completed = 'Completed',
  Rejected = 'Rejected',
  NotSubmitted = 'NotSubmitted',
  WaitingOnStatus = 'WaitingOnStatus',
  Failed = 'Failed',
}

export interface PartialDocument {
  email: string;
  type: string;
  id: string;
  zatcaError: ZatcaMessage[];
}

export interface ReSubmitToZatcaInput {
  dialogVisible: boolean;
  wholeInvoice: boolean;
  document?: MappedBillingDocument | PartialDocument;
}


