import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invoiceType',
})
export class InvoiceTypePipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'B2B':
        return 'b2b';
      case 'B2C':
        return 'b2c';
      default:
        return 'b2b';
    }
  }
}
