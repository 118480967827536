export const environment = {
  name: 'mantis',
  userPoolId: 'us-east-1_dv8Kciyhu',
  userPoolClientId: '7gfslgclm12ef0uaotfj5vrvd4',
  cognitoDomain: 'qa-admin-rewaa.auth.us-east-1.amazoncognito.com',
  scopes: [
    'phone',
    'email',
    'openid',
    'profile',
    'aws.cognito.signin.user.admin',
  ],
  redirectSignIn: [`${window.location.origin}/accounts`],
  redirectSignOut: [`${window.location.origin}/login`],
  responseType: 'token',
  launchDarklyClientSideId: '6359237ce4483b1158727b7b',
  crmAccountUrl: 'https://crmsandbox.zoho.com/crm/newstaging/tab/Accounts/',
  chargebeeAccountUrl: 'https://rewaa-test.chargebee.com/d/customers/',
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  appVersion: require('../../package.json').version,
  enableDatadog: false,
  enableFullStory: false,
  datadogApplicationId: 'dea2a987-fdaa-49b3-aff9-b1840fe4b2bc',
  datadogClientToken: 'pub4e8cd88ba279a0885c9643c29c611449',
};
