import { documentExists, processExists, windowExists } from './helpers.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
// Tested with @angular/core 16.0.0
function angularWebDetect() {
  const angularVersionSetInDocument = Boolean(documentExists() && document.querySelector('[ng-version]'));
  const angularContentSetInWindow = Boolean(
  // @ts-ignore
  windowExists() && typeof window['ng'] !== 'undefined');
  return angularVersionSetInDocument || angularContentSetInWindow;
}
function angularSSRDetect() {
  return processExists() && typeof process.env === 'object' && process.env['npm_lifecycle_script']?.startsWith('ng ') || false;
}
export { angularSSRDetect, angularWebDetect };
