<div
  class="rwa__datatable-paginator flex align-items-center justify-content-between px-4 py-2"
  [ngClass]="{ rtl: lng === 'ar' }"
>
  <div
    class="flex align-items-center white-space-nowrap gap-2"
    [ngClass]="{ 'flex-row-reverse': lng === 'ar' }"
  >
    <span class="text-sm text-neutral-70">{{
      'No. of Results' | translate
    }}</span>
    <select
      [formControl]="rowsFormControl"
      class="border-1 border-neutral-20 border-round-sm px-1"
    >
      <option
        *ngFor="let recordCount of tableAttributes.recordCounts"
        [value]="recordCount"
      >
        {{ recordCount }}
      </option>
    </select>
    <span class="text-sm text-neutral-70">
      {{ tableAttributes.total }} records found
    </span>
  </div>

  <span class="text-sm text-neutral-70">
    {{ tableAttributes.first / tableAttributes.rows + 1 | number: '1.0-0' }} of
    @if (tableAttributes.total < tableAttributes.rows) {
      1
    } @else {
      {{ tableAttributes.total / tableAttributes.rows | number: '1.0-0' }}
    }
  </span>

  <div>
    <p-paginator
      class="white-space-nowrap"
      (onPageChange)="handleOnPageChanged($event)"
      [rows]="tableAttributes.rows"
      [totalRecords]="tableAttributes.total"
      [first]="tableAttributes.first"
      [pageLinkSize]="tableAttributes.pageLinkSize"
      [showFirstLastIcon]="tableAttributes.showFirstLastIcon || true"
    ></p-paginator>
  </div>
</div>
