// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const convertToTitleCase = (name: string) => {
  const string = name.toLowerCase();
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatDate = (dateString: string | undefined): string => {
  const placeholderResponse = '-';
  try {
    if (!dateString) return placeholderResponse;

    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', {
      calendar: 'gregory',
      dateStyle: 'medium',
    });
    return formattedDate ?? placeholderResponse;
  } catch (error: unknown) {
    return placeholderResponse;
  }
};
