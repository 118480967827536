import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';

export interface IBreadCrumb {
  breadCrumbItems: MenuItem[];
}

@Injectable()
export class BreadCrumbService {
  private breadCrumbSubject = new BehaviorSubject<IBreadCrumb | undefined>(
    undefined,
  );

  setBreadCrumb(breadCrumb: IBreadCrumb): void {
    this.breadCrumbSubject.next(breadCrumb);
  }

  getBreadCrumb(): Observable<IBreadCrumb | undefined> {
    return this.breadCrumbSubject.asObservable();
  }

  resetBreadCrumb(): void {
    this.breadCrumbSubject.next({ breadCrumbItems: [] });
  }
}
