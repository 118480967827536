import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
  selector: 'rwa-additional-payment',
  templateUrl: './additional-payment.component.html',
  styleUrl: './additional-payment.component.scss',
})
export class AdditionalPaymentComponent implements OnInit {
  @Input() dialogVisible: boolean;

  @Output() closeDialog = new EventEmitter<undefined>();

  transactionList: any = [];

  ngOnInit(): void {
    this.getTransactionList();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getTransactionList() {
    this.transactionList = [
      {
        invoice: '105843',
        paymentMethod: 'Bank Transfer',
        bankName: 'Alinma Bank',
        paidAmount: '8,942.00',
        paidAmountCurrency: 'SR',
        paymentDate: '12313123123123',
      },
    ];
  }

  close(): void {
    this.closeDialog.emit();
  }
}
