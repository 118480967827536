// eslint-disable-next-line import/no-extraneous-dependencies
import Currency from 'currency.js';

const OPTIONS = { precision: 8 };

export class CalculatorService {
  static add(a: number, b: number): number {
    return new Currency(a, OPTIONS).add(b).value;
  }

  static subtract(a: number, b: number): number {
    return new Currency(a, OPTIONS).subtract(b).value;
  }

  static multiply(a: number, b: number): number {
    return new Currency(a, OPTIONS).multiply(b).value;
  }

  static divide(a: number, b: number): number {
    return new Currency(a, OPTIONS).divide(b).value;
  }
}
