import { Injectable } from '@angular/core';
import { SessionStorageKey } from '../constants/constants';

@Injectable()
export class SessionStorageService {
  public setItem<T>(key: SessionStorageKey, value: T): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  public getItem<T>(key: SessionStorageKey): T {
    return sessionStorage.getItem(key) as unknown as T;
  }

  public removeItem(key: SessionStorageKey): void {
    sessionStorage.removeItem(key);
  }

  public clear(): void {
    sessionStorage.clear();
  }
}
