import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { PaymentDTO } from '@rewaa-team/types';
import { AccountService } from '../../../../accounts/account.service';
import { SpinnerService } from '../../../services/spinner.service';
import { formatDate } from '../../../utils';
import { CalculatorService } from '../../../services/calculator.service';
import { FeatureFlagService } from '../../../types/feature-flag.service.interface';
import { FeatureFlagEnum } from '../../../constants/feature-flag.constants';
import { CustomToastService } from '../../../services/custom-toast.service';

@Component({
  selector: 'rwa-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrl: './transaction-list.component.scss',
})
export class TransactionListComponent implements OnInit, OnChanges {
  constructor(
    private accountService: AccountService,
    public spinnerService: SpinnerService,
    private customToastService: CustomToastService,
    private featureFlagService: FeatureFlagService,
    public router: Router,
  ) {}

  email = '';

  hasPartialPaymentPermission = false;

  paymentDaysLeft: number | undefined = undefined;

  @Input() subscriptionId: string;

  ngOnInit(): void {
    this.featureFlagService
      .isEnabled(
        FeatureFlagEnum.PartialPaymentPermission as FeatureFlagEnum.AdminPanelV2,
      )
      .subscribe((res) => {
        console.log(`Partial payment permission: ${res}`);
        this.hasPartialPaymentPermission = res;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.transactionListInput &&
      this.transactionListInput.dialogVisible
    ) {
      this.spinnerService.startLoading();
      this.accountService
        .getPaymentProofs(
          this.transactionListInput.cbInvoice.id,
          this.subscriptionId,
        )
        .subscribe({
          next: (paymentProofs) => {
            this.transactionList = paymentProofs.transaction.map(
              (proof: PaymentDTO) => ({
                ...proof,
                paymentDate: formatDate(proof.paymentDate.toString()),
                paidAmountCurrency: 'SR',
                invoice: this.transactionListInput.cbInvoice.id,
                invoiceUrls: proof.invoiceUrls || [],
              }),
            );
            this.email = paymentProofs.merchant.email;
            this.spinnerService.stopLoading();
            this.handlePartialPayment(paymentProofs.transaction);
          },
          error: (err) => {
            this.customToastService.error(err.error.message);
            this.spinnerService.stopLoading();
            this.close();
          },
        });
    }
  }

  @Input() transactionListInput: {
    dialogVisible: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cbInvoice: any | undefined;
  };

  @Output() closeDialog = new EventEmitter<undefined>();

  transactionList: any = [];

  close(): void {
    this.closeDialog.emit();
  }

  routeToPartialPayment(): void {
    this.router.navigateByUrl(
      `accounts/checkout/partial/${this.subscriptionId}/${this.transactionListInput.cbInvoice.id}/${this.email}`,
      {
        state: {
          data: this.transactionList,
          invoice: this.transactionListInput.cbInvoice,
        },
      },
    );
  }

  handlePartialPayment(paymentProofs: PaymentDTO[]): void {
    const { cbInvoice } = this.transactionListInput;

    const amountPaid = paymentProofs.reduce(
      (curr, transaction) => curr + transaction.paidAmount,
      0,
    );
    const totalInvoiceAmount = CalculatorService.divide(cbInvoice.total, 100);

    if (amountPaid !== totalInvoiceAmount) {
      const lastPayment = paymentProofs[0];
      const lastPaymentDate = new Date(lastPayment.paymentDate);
      const today = new Date();

      const difference = today.getTime() - lastPaymentDate.getTime();
      this.paymentDaysLeft =
        14 - Math.floor(difference / (1000 * 60 * 60 * 24));
    }
  }
}
