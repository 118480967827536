import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  signal,
  SimpleChanges,
} from '@angular/core';
import { SecondaryIDEnum } from '@rewaa-team/rewaa-common';
import { AccountService } from '../../../../accounts/account.service';
import { PartialDocument, ReSubmitToZatcaInput } from '../../../types';
import {
  InvoiceDtoWithValidity,
  MappedBillingDocument,
  RetryAndResubmissionPayload,
} from '../../../../accounts/types';
import { CustomToastService } from '../../../services/custom-toast.service';

@Component({
  selector: 'rwa-re-submit-to-zatca',
  templateUrl: './re-submit-to-zatca.component.html',
  styleUrl: './re-submit-to-zatca.component.scss',
})
export class ReSubmitToZatcaComponent implements OnChanges {
  constructor(
    private readonly accountService: AccountService,
    private customToastService: CustomToastService,
  ) {}

  @Input({ required: true }) reSubmitToZatcaInput: ReSubmitToZatcaInput;

  loading = false;

  invoice: MappedBillingDocument;

  showSpinner = false;

  isPrintEnabled = false;

  reSubmitLabel: string = 'Fix and Re-submit Invoice';

  documentType: string;

  completed = false;

  zatcaErrors: string[];

  billingInfoDialogVisible: boolean = false;

  activeIndex = 0;

  subscriptionId: string;

  email: string;

  invoiceData = signal<InvoiceDtoWithValidity>({
    isValid: false,
    vatNumber: '',
    city: '',
    countryCode: '',
    country: '',
    streetName: '',
    postalCode: '',
    districtName: '',
    companyName: '',
    additionalIdentifier: '',
    additionalIdentifierNumber: '',
    buildingNumber: '',
    additionalNumber: '',
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() closeDialog = new EventEmitter<any>();

  @Output() routeToAccountDetails = new EventEmitter<string | undefined>();

  @Output() print = new EventEmitter<MappedBillingDocument>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.reSubmitToZatcaInput &&
      changes.reSubmitToZatcaInput.currentValue.dialogVisible === true
    ) {
      console.log(changes.reSubmitToZatcaInput.currentValue.document)
      this.activeIndex = 0;
      this.invoiceData.set({
        isValid: false,
        vatNumber: '',
        city: '',
        streetName: '',
        postalCode: '',
        districtName: '',
      });
      this.showErrorsIfAny();
      this.setResubmitLabel();
      this.loading = false;
      this.completed = false;

      if (
        this.reSubmitToZatcaInput.document.type === 'invoice' &&
        !this.reSubmitToZatcaInput.wholeInvoice
      ) {
        // API CALL to fetch the billing info and store it in the invoiceData object
        const billingInfoDialogInput = {
          id: '5838',
          type: 'invoice',
          status: 'paid',
          vatNumber: '124134123456900',
          date: 1732654800,
          dueDate: 1732654800,
          paidAt: 1732654800,
          total: 964657,
          subTotal: 838832,
          tax: 125825,
          amountPaid: 0,
          amountAdjusted: 0,
          creditsApplied: 964657,
          dueAmount: 0,
          lineItems: [
            {
              id: 'plan_special_advance_annually',
              itemId: 'plan_special_advance',
              type: 'plan',
              quantity: 1,
              unitAmount: 484038,
              amount: 484038,
              taxAmount: 72606,
              taxRate: 15,
              discountAmount: 0,
              period: 1,
              unit: 'year',
              name: 'Special Advance Annually',
              dateFrom: 1732654800,
              dateTo: 1762894799,
              taxableAmount: 484038,
            },
            {
              id: 'location_annually',
              itemId: 'location',
              type: 'addon',
              quantity: 1,
              unitAmount: 239726,
              amount: 239726,
              taxAmount: 35959,
              taxRate: 15,
              discountAmount: 0,
              period: 1,
              unit: 'year',
              name: 'Location  Annually',
              dateFrom: 1732654800,
              dateTo: 1762894799,
              taxableAmount: 239726,
            },
            {
              id: 'accounting_annually',
              itemId: 'accounting',
              type: 'addon',
              quantity: 1,
              unitAmount: 115068,
              amount: 115068,
              taxAmount: 17260,
              taxRate: 15,
              discountAmount: 0,
              period: 1,
              unit: 'year',
              name: 'Accounting Annually',
              dateFrom: 1732654800,
              dateTo: 1762894799,
              taxableAmount: 115068,
            },
          ],
          billingAddress: {
            company: 'asdasdsa',
            line1: 'bcd, additional',
            line2: 'My district changed',
            line3: 'Building',
            city: 'Hacker day',
            state: 'Hacker day',
            country: 'المملكة العربية السعودية',
            zip: '21121',
            validation_status: 'not_validated',
            object: 'billing_address',
          },
          credit_note_visibility: false,
          customerId: 'rw_lili_379_local',
          lastUpdated: 1733476401,
          totalCollectedAmount: 964657,
          txnData: [],
          excessBalance: 0,
          printingSubtotal: 838832,
          totalDiscount: 0,
          totalTaxableAmount: 838832,
          additionalIdType: 'Commercial Registration',
          additionalIdValue: '1121212121',
        };

        this.invoiceData.set({
          isValid: false,
          vatNumber:
            this.invoiceData().vatNumber || billingInfoDialogInput.vatNumber,
          companyName:
            this.invoiceData().companyName ||
            billingInfoDialogInput.billingAddress.company,
          additionalIdentifier:
            this.invoiceData().additionalIdentifier ||
            billingInfoDialogInput.additionalIdType,
          additionalIdentifierNumber:
            this.invoiceData().additionalIdentifierNumber ||
            billingInfoDialogInput.additionalIdValue,
          city:
            this.invoiceData().city ||
            billingInfoDialogInput.billingAddress.city,
          country:
            this.invoiceData().country ||
            billingInfoDialogInput.billingAddress.country,
          streetName:
            this.invoiceData().streetName ||
            billingInfoDialogInput.billingAddress.line1?.split(', ')[0],
          postalCode:
            this.invoiceData().postalCode ||
            billingInfoDialogInput.billingAddress.zip,
          districtName:
            this.invoiceData().districtName ||
            billingInfoDialogInput.billingAddress.line2,
          additionalNumber:
            this.invoiceData().additionalNumber ||
            billingInfoDialogInput.billingAddress.line1?.split(', ')[1] ||
            '',
          buildingNumber:
            this.invoiceData().buildingNumber ||
            billingInfoDialogInput.billingAddress?.line3 ||
            '',
        });
      }
    }
  }

  handlePrint(): void {
    this.closeDialog.emit();
    if (this.reSubmitToZatcaInput.wholeInvoice)
      this.print.emit(
        this.reSubmitToZatcaInput.document as MappedBillingDocument,
      );
  }

  handleRouteToAccountDetails(): void {
    this.closeDialog.emit();
    if (this.reSubmitToZatcaInput.wholeInvoice)
      this.routeToAccountDetails.emit();
    else
      this.routeToAccountDetails.emit(
        (this.reSubmitToZatcaInput.document as PartialDocument).email,
      );
  }

  setResubmitLabel(): void {
    if (this.reSubmitToZatcaInput.wholeInvoice)
      this.invoice = {
        ...(this.reSubmitToZatcaInput.document as MappedBillingDocument),
      };
    const type = this.reSubmitToZatcaInput.wholeInvoice
      ? (this.reSubmitToZatcaInput.document as MappedBillingDocument).type
      : (this.reSubmitToZatcaInput.document as PartialDocument).type;
    this.documentType = type === 'invoice' ? 'Invoice' : 'Credit Note';
    this.reSubmitLabel =
      this.reSubmitToZatcaInput.document.type === 'invoice'
        ? 'Fix and Re-submit Invoice'
        : 'Re-submit Credit Note';
  }

  reSubmitToZatca(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // const updatedDocument: MappedBillingDocument = {
    //   ...this.reSubmitToZatcaInput.document,
    //   vatNumber: this.invoiceData().vatNumber,
    //   billingAddress: {
    //     ...this.reSubmitToZatcaInput.document.billingAddress,
    //     city: this.invoiceData().city,
    //     country: this.invoiceData().country,
    //     company: this.invoiceData().companyName,
    //     line1:
    //       this.invoiceData().streetName +
    //       (this.invoiceData().additionalNumber
    //         ? ', ' + this.invoiceData().additionalNumber
    //         : ''),
    //     line2: this.invoiceData().districtName,
    //     line3: this.invoiceData().buildingNumber,
    //     zip: this.invoiceData().postalCode,
    //   },
    //   additionalIdType: this.invoiceData().additionalIdentifier
    //     ? this.mapToSecondaryIDEnum(this.invoiceData().additionalIdentifier)
    //     : null,
    //   additionalIdValue: this.invoiceData().additionalIdentifierNumber ?? null,
    // };

    let payload: RetryAndResubmissionPayload = {
      id: this.reSubmitToZatcaInput.document.id,
      type: this.reSubmitToZatcaInput.document.type,
    };

    if (this.reSubmitToZatcaInput.document.type === 'invoice') {
      payload = { ...payload, invoiceData: this.invoiceData() };
    }
    console.log('Payload of Retrying: ', payload);

    this.loading = true;
    // const payload = this.reSubmitToZatcaInput.wholeInvoice
    //   ? {
    //       id: (this.reSubmitToZatcaInput.document as MappedBillingDocument).id,
    //       type: (this.reSubmitToZatcaInput.document as MappedBillingDocument)
    //         .type,
    //     }
    //   : {
    //       id: (this.reSubmitToZatcaInput.document as PartialDocument).id,
    //       type: (this.reSubmitToZatcaInput.document as PartialDocument).type,
    //     };

    this.accountService.retryInvoicesOrCreditNotes(payload).subscribe({
      next: () => {
        this.loading = false;
        this.completed = true;
      },
      complete: () => {
        this.loading = false;
        this.completed = true;
      },
      error: (err) => {
        this.customToastService.error(err.error.message);
        this.completed = false;
        this.loading = false;
        this.closeDialog.emit();
      },
    });
  }

  mapToSecondaryIDEnum(identifier: any): SecondaryIDEnum {
    const mapping: Record<string, SecondaryIDEnum> = {
      'Commercial Registration': SecondaryIDEnum.CommercialRegistration,
      'Freelance License': SecondaryIDEnum.FreelanceLicense,
      'Investment License': SecondaryIDEnum.InvestmentLicense,
      'MLSD License': SecondaryIDEnum.MLSDLicense,
      'MOMRA License': SecondaryIDEnum.MOMRALicense,
      'Municipal License': SecondaryIDEnum.MunicipalLicense,
      'Other ID': SecondaryIDEnum.OtherID,
      'SAGIA License': SecondaryIDEnum.SAGIALicense,
    };
    return mapping[identifier];
  }

  moveToFix(): void {
    if (this.reSubmitToZatcaInput.document.type === 'invoice') {
      this.activeIndex = 1;
    } else {
      this.reSubmitToZatca();
    }
  }

  back(): void {
    this.activeIndex = 0;
  }

  public get isSecondStepDisabled(): boolean {
    return !this.invoiceData().isValid;
  }

  showErrorsIfAny(): void {
    this.zatcaErrors = this.reSubmitToZatcaInput.document.zatcaError?.map(
      (message) => message.message,
    );
  }

  close(): void {
    this.closeDialog.emit();
  }
}
