<div>
  <span
    class="p-dropdown text-gray-800 bg-primary-100 border-neutral-20 px-3 py-2 flex items-center flex gap-2"
  >
    <span>
      <span class="text-primary-500">{{ name }}:</span>
      <span class="text-primary-900 pl-1">{{ displayValue }}</span>
    </span>
    <div
      class="cursor-pointer pl-2"
      style="border-left: 1px solid darkgray"
      (click)="emitRemove()"
    >
      <i class="fa-solid fa-xmark text-primary-500"></i>
    </div>
  </span>
</div>
