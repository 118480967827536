import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, from, throwError } from 'rxjs';
import { catchError, finalize, first, switchMap } from 'rxjs/operators';
import { UserAuthenticationService } from '../../auth/services/user-authentication.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private userAuthenticationService: UserAuthenticationService,
  ) {}

  private async redirectToLogin() {
    await this.userAuthenticationService.logout();
    this.router.navigate(['/login']).then();
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          from(this.userAuthenticationService.logout()).pipe(
            first(),
            switchMap(() => this.router.navigate(['/login']).then()),
          );
        }
        return throwError(() => err);
      }),
      finalize(() => {}),
    );
  }
}
