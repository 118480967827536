import { Observable } from 'rxjs';
import { FeatureFlags } from '../enums/feature-flag.enum';

export abstract class FeatureFlagService {
  abstract isEnabled<T extends keyof FeatureFlags>(
    featureFlagName: T,
    defaultValue?: unknown,
  ): Observable<FeatureFlags[T]>;

  abstract variation<T extends keyof FeatureFlags>(
    featureFlagName: T,
    defaultValue?: unknown,
  ): Observable<FeatureFlags[T]>;
}
