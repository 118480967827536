import { defaultConfig, cognitoIdentityTransferHandler, buildHttpRpcRequest, getSharedHeaders } from './base.mjs';
import '../../utils/getClientInfo/getClientInfo.mjs';
import '../../utils/retry/retry.mjs';
import '@aws-crypto/sha256-js';
import '@smithy/util-hex-encoding';
import { parseMetadata } from '../../clients/serde/responseInfo.mjs';
import { parseJsonError, parseJsonBody } from '../../clients/serde/json.mjs';
import { composeServiceApi } from '../../clients/internal/composeServiceApi.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const getIdSerializer = (input, endpoint) => {
  const headers = getSharedHeaders('GetId');
  const body = JSON.stringify(input);
  return buildHttpRpcRequest(endpoint, headers, body);
};
const getIdDeserializer = async response => {
  if (response.statusCode >= 300) {
    const error = await parseJsonError(response);
    throw error;
  } else {
    const body = await parseJsonBody(response);
    return {
      IdentityId: body.IdentityId,
      $metadata: parseMetadata(response)
    };
  }
};
/**
 * @internal
 */
const getId = composeServiceApi(cognitoIdentityTransferHandler, getIdSerializer, getIdDeserializer, defaultConfig);
export { getId };
