export enum SessionStorageKey {
  LoginInProcess = 'LoginInProcess',
}

export enum RewaaInformation {
  vatNumber = '310188259700003',
}

export const CheckOutItemTypes = {
  Plan: 'plan',
  Location: 'location',
  Application: 'application',
  Charge: 'charge',
};

export const SignInEvents = {
  SignIn: 'signIn',
  SignInWithRedirect: 'signInWithRedirect',
  SignInWithRedirectFailure: 'signInWithRedirect_failure',
  SignedIn: 'signedIn',
};

export const ErrorCodes = {
  AccountNotExists: 'AccountNotExists',
  PreTokenGeneration: 'PreTokenGeneration',
  BadDomainName: 'BadDomainName',
};

export const ErrorMessages = {
  DomainMismatch:
    'Access denied. Your email doesn’t belong to Rewaatech domain',
  AccountNotExists:
    'Access denied. You do not have permission to access the admin panel.',
};
