import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rwa-info',
  templateUrl: './info.component.html',
  styleUrl: './info.component.scss',
})
export class InfoComponent {
  @Input({ required: true }) translationKey: string = '';

  @Input({ required: true }) text: string = '';

  @Input({ required: true }) type: string = '';

  @Input() buttonTitle: string = '';

  @Input() buttonUrl: string = '';

  @Input() icon: string;

  @Output() buttonFunction = new EventEmitter<void>();

  click(): void {
    this.buttonFunction.emit();
  }
}
