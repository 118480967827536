import { Injectable } from '@angular/core';
import { FetchUserAttributesOutput } from 'aws-amplify/auth';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class UserService {
  private user$: BehaviorSubject<FetchUserAttributesOutput | undefined> =
    new BehaviorSubject<FetchUserAttributesOutput | undefined>(undefined);

  setUser(user: FetchUserAttributesOutput): void {
    this.user$.next(user);
  }

  clearUser(): void {
    this.user$.next(undefined);
  }

  /** @todo fix the return type */
  getUser(): Observable<FetchUserAttributesOutput | undefined> {
    return this.user$.asObservable();
  }
}
