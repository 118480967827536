export enum RegexEnum {
  numberOnly = '^[0-9]+$',
  numberWithDecimal = '^[0-9]+(\\.[0-9]+)?$',
  alphaNumberOnly = '^[a-zA-Z0-9_ ]*$',
  alphaNumberAndArabicOnly = '^[a-zA-Z0-9\u0600-\u06FF_ ]*$',
  vatNumber = '.{0,20}',
  phoneNumberNineDigit = '^5[0-9]{8}',
  phoneNumberTenDigit = '^0[0-9]{9}',
  phoneNumber = '^(5[0-9]{8}$)|^(\\+9665[0-9]{8}$)|^(009665[0-9]{8}$)|^(05[0-9]{8}$)|^(9665[0-9]{8}$)',
  phoneNumberForCognito = '^(0[0-9]{9}|5[0-9]{8})$',
  buildingNumber = '^[0-9]{4}$',
  acceptfourNumber = '^[0-9]{4}$',
  acceptFiveNumber = '^[0-9]{5}$',
  acceptSixChars = '.{6,}',
  email = '^[a-zA-Z0-9_\\-]+(\\.[a-zA-Z0-9_\\-]+)*@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\\.([a-zA-Z]+)$',
  password = '^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).{8,}$',
  url = '[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)',
}
