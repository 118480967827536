<p-dialog
  [showHeader]="false"
  [(visible)]="reSubmitToZatcaInput.dialogVisible"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '100%', maxWidth: '624px' }"
>
  @if (loading && !completed) {
    <div
      class="flex flex-column align-items-center justify-content-center w-100 h-100"
    >
      <p-progressSpinner
        styleClass="spinner"
        [style]="{ width: '80px', height: '80px' }"
        animationDuration=".5s"
      ></p-progressSpinner>
      <label class="font-medium text-lg text-neutral-90 mt-3">
        Your {{ documentType }} is being reviewed by ZATCA
      </label>
    </div>
  } @else if (completed && !loading) {
    <div class="flex flex-column gap-6 pt-3">
      <div class="flex flex-column align-items-center gap-4">
        <img
          src="../../../../../assets/icons/success-thumb.svg"
          height="88"
          width="88"
        />
        <label class="font-medium text-2xl text-neutral-90"
          >Your {{ documentType }} is approved by ZATCA successfully</label
        >
      </div>
      <div class="flex flex-column align-items-center gap-4">
        <!--            <div>-->
        <!--                <p-button label="Share invoice Via WhatsApp" (click)="close('')"></p-button>-->
        <!--            </div>-->
        <div class="flex justify-content-center gap-3">
          <p-button
            label="Go to customer account"
            [outlined]="true"
            (click)="handleRouteToAccountDetails()"
            styleClass="border-neutral-20"
          ></p-button>
          <p-button
            label="Print invoice"
            (click)="handlePrint()"
            [outlined]="true"
          ></p-button>
        </div>
      </div>
    </div>
  } @else {
    @if(activeIndex === 1){
        <rwa-billing-info-dialog
          [billingInfoDialogInput] = "reSubmitToZatcaInput"
          [(invoiceData)] = 'invoiceData'
        >
        </rwa-billing-info-dialog>
          <div class="flex align-items-center justify-content-end gap-3">
            <p-button
              label="Back"
              [text]="true"
              (click)="back()"
            ></p-button>
            <p-button
              [disabled]="isSecondStepDisabled"
              label="Confirm and Submit"
              (click)="reSubmitToZatca()"
            ></p-button>
          </div>
    }@else {
    <div class="flex flex-column gap-4 pb-4">
      <!-- Icon and Title -->
      <div class="flex flex-column align-items-center gap-3">
        <img
          src="../../../../../assets/icons/cross.svg"
          alt="Failure Icon"
          height="88"
          width="88"
        />
        <label class="font-medium text-2xl text-neutral-90">
          Your {{documentType}} submission to ZATCA has failed
        </label>
      </div>

      <div
        class="flex flex-column gap-2 border-1 border-neutral-20"
        style="max-height: 200px; overflow-y: auto"
      >
        <ng-container *ngIf="zatcaErrors?.length > 0; else noErrors">
          <ng-container *ngFor="let error of zatcaErrors; let i = index">
            <div class="m-2">
              <h5 class="mb-2">Error {{ i + 1 }} - message</h5>
              <div class="flex align-items-start">
                <label class="font-small text-neutral-70">
                  {{ error }}
                </label>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #noErrors>
          <div class="flex align-items-center justify-content-center">
            <label class="font-medium text-neutral-70"
              >No Zatca errors are present</label
            >
          </div>
        </ng-template>
      </div>

      <!-- Buttons -->
        <div class="flex justify-content-center gap-3 mt-4">
          <p-button
          [label]="reSubmitLabel"
          [outlined]="true"
          styleClass="border-neutral-20"
          (click)="moveToFix()"
          ></p-button>
        </div>
        <div class="flex justify-content-center gap-3">
          <p-button
          label="Go to customer account"
          [outlined]="true"
          styleClass="border-neutral-20"
          (click)="handleRouteToAccountDetails()"
        ></p-button>
        <p-button
        label="Print {{ documentType }}"
        (click)="handlePrint()"
        ></p-button>
      </div>
    </div>
    }
  }
</p-dialog>


