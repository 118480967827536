<p-dialog [(visible)]="transactionListInput.dialogVisible" [modal]="true" [draggable]="false" [resizable]="false" [style]="{width: '100%', maxWidth: '835px'}">
    <ng-template pTemplate="header">
        <div class="flex flex-column">
            <label class="text-lg font-medium">{{ 'Transaction List' | translate }}</label>
            <span class="text-base mt-2">{{ 'All Transactions uploaded for this invoice' | translate }}</span>
        </div>
    </ng-template>

    <rwa-app-spinner *ngIf="spinnerService._loading$ | async"></rwa-app-spinner>
    <div class="flex flex-column gap-6">
        @if(hasPartialPaymentPermission && paymentDaysLeft >= 0 && transactionListInput.cbInvoice?.status !== 'paid'){
            <rwa-info
                [buttonTitle]="'Complete The Payment'"
                (buttonFunction)="routeToPartialPayment()"
                [icon]="'fa-regular fa-triangle-exclamation'"
                [type]="'warning'"
                [translationKey]="''"
                [text]="'The Invoice not fully Paid, payment due date is in <b>'+paymentDaysLeft+'</b> days!'"
            ></rwa-info>
        }
        <div class="rwa__datatable flex flex-column">
            <p-table
                [value]="transactionList"
                [lazy]="true"
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th
                            pFrozenColumn
                            class="leftFrozenColumn"
                            [ngStyle]="{ 'min-width': '106px', 'max-width': '106px' }"
                            [frozen]="true"
                            [alignFrozen]="'left'">Invoice No.</th>
                        <th>Payment Method</th>
                        <th>Bank Name</th>
                        <th>Paid Amount</th>
                        <th>Payment Date</th>
                        <th
                            pFrozenColumn
                            class="rightFrozenColumn rw__customize-head"
                            [ngStyle]="{ 'min-width': '130px', 'max-width': '130px' }"
                            [frozen]="true"
                            [alignFrozen]="'right'"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-transaction>
                    <tr>
                        <td
                            pFrozenColumn
                            class="leftFrozenColumn"
                            [ngStyle]="{ 'min-width': '106px', 'max-width': '106px' }"
                            [frozen]="true"
                            [alignFrozen]="'left'">
                            <div class="text-primary fw-500">
                                {{ transaction.invoice | translate }}
                            </div>
                        </td>
                        <td>{{ transaction.paymentMethod | translate }}</td>
                        <td>{{ transaction.bankName | translate }}</td>
                        <td>{{ transaction.paidAmount | translate }} {{ transaction.paidAmountCurrency | translate }}</td>
                        <td>{{ transaction.paymentDate | translate }}</td>
                        <td
                            pFrozenColumn
                            class="rightFrozenColumn rw__customize-head"
                            [ngStyle]="{ 'min-width': '130px', 'max-width': '130px' }"
                            [frozen]="true"
                            [alignFrozen]="'right'">
                            <div class="flex align-items-center justify-content-center">
                                <button [disabled]="true" pButton pRipple type="button" icon="fa-regular fa-pen" 
                                  class="p-button-rounded p-button-text text-sm text-neutral-70"></button>
                                <button [disabled]="true" pButton pRipple type="button" icon="fa-regular fa-arrow-up-from-bracket" 
                                  class="p-button-rounded p-button-text text-sm text-neutral-70"></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <div class="flex align-items-center justify-content-end gap-3">
            <p-button label="{{ 'Cancel' | translate }}" [text]="true" (click)="close()"></p-button>
            <!-- <p-button label="{{ 'Export All' | translate }}" (click)="close()"></p-button> -->
        </div>
    </ng-template>
</p-dialog>