
<p-dialog [showHeader]="false" [(visible)]="dialogVisible" [modal]="true" [draggable]="false" [resizable]="false" [style]="{width: '100%', maxWidth: '624px'}">
    <div class="flex flex-column gap-6 pt-3">
        <div class="flex flex-column align-items-center gap-4">
            <img src="../../../../../assets/icons/success-thumb.svg" height="88" width="88" />
            <label class="font-medium text-2xl text-neutral-90">{{ msg }}</label>
        </div>
        <div class="flex flex-column align-items-center gap-4">
<!--            <div>-->
<!--                <p-button label="Share invoice Via WhatsApp" (click)="close('')"></p-button>-->
<!--            </div>-->
            <div class="flex justify-content-center gap-3">
                <p-button label="Go to customer account" [outlined]="true" styleClass="border-neutral-20" (click)="close('details')"></p-button>
                <p-button label="Print invoice" [outlined]="true" (click)="close('print')"></p-button>
            </div>
        </div>
    </div>
</p-dialog>
